<div class="modal-header">
    <h4 class="modal-title">Edit partner</h4>
</div>
<div class="modal-body">
    <div>
      <div class="alert alert-danger" role="alert" *ngIf="errorModel">
        <b>Error when administrating partner. Error Message: {{errorModel.errorMessage}}</b>
      </div>
        <fieldset class="border p-2 mb10 mt10">
            <legend class="float-none w-auto legend-style"><span style="margin-left: 5px; margin-right: 5px;">Partner details</span></legend>
            <div class="form-group row mb5">
                <label for="partnerId" class="col-sm-2 col-form-label">Partner Id <span class="req-color">*</span></label>
                <div class="col-sm-6">
                    <input type="text" class="form-control" required id="partnerId" name="partnerId" placeholder="Partner Id" [(ngModel)]="partner.partnerId" #partnerId="ngModel" (ngModelChange)="partner.partnerId = $event.toLowerCase()">
                    <div [hidden]="partnerId.valid || partnerId.pristine" class="text-danger">
                        Partner Id is required
                    </div>
                </div>
            </div>

            <div class="form-group row mb5">
                <label for="partnerName" class="col-sm-2 col-form-label">Name <span class="req-color">*</span></label>
                <div class="col-sm-6">
                    <input type="text" class="form-control" required id="partnerName" name="partnerName" placeholder="Name" [(ngModel)]="partner.name" #partnerName="ngModel">
                    <div [hidden]="partnerName.valid || partnerName.pristine" class="text-danger">
                        Name is required
                    </div>
                </div>
            </div>

            <div class="form-group row mb5">
                <label for="partnerDescription" class="col-sm-2 col-form-label">Description <span class="req-color">*</span></label>
                <div class="col-sm-6">
                    <input type="text" class="form-control" required id="partnerDescription" name="partnerDescription" placeholder="Description" [(ngModel)]="partner.description" #partnerDescription="ngModel">
                    <div [hidden]="partnerDescription.valid || partnerDescription.pristine" class="text-danger">
                        Description is required
                    </div>
                </div>
            </div>
        </fieldset>
        <fieldset class="border p-2 mb10 mt10" *ngIf="resolversddl">
            <legend class="float-none w-auto legend-style"><span style="margin-left: 5px; margin-right: 5px;">Resolver</span></legend>
            <div class="form-group row" >
                <label for="resolverDDL" class="col-sm-2 col-form-label control-label">Resolver</label>
                <div class="col-sm-6">
                  <select dropdown id="resolverDDL" name="resolverDDL" class="form-select form-select-sm control-label" [(ngModel)]="resolverId">
                    <option value="-1">--- Select resolver ---</option>
                    <option *ngFor="let item of resolversddl" [value]="item.value">
                      {{item.text}}
                    </option>
                  </select>
                </div>
              </div>
        </fieldset>
        <fieldset class="border p-2 mb10 mt10" *ngIf="preStepClassesDDL">
          <legend class="float-none w-auto legend-style"><span style="margin-left: 5px; margin-right: 5px;">Prestep</span></legend>
          <div style="margin-bottom: 10px;">
            <span class="text-muted"><small>If the incomming file's format is CSV, Flatfile etc in need to be converted into Xml in order to process it. Select prestep class if the incomming message need to be changed to Xml.</small></span>
        </div>
          <div class="form-group row" >
              <label for="resolverDDL" class="col-sm-2 col-form-label control-label">Prestep</label>
              <div class="col-sm-6">
                <select dropdown id="resolverDDL" name="resolverDDL" class="form-select form-select-sm control-label" [(ngModel)]="preStepClassId">
                  <option value="-1">--- Select prestep class ---</option>
                  <option *ngFor="let item of preStepClassesDDL" [value]="item.value">
                    {{item.text}}
                  </option>
                </select>
              </div>
            </div>
      </fieldset>
        <div class="mt10">
            <ul ngbNav #nav="ngbNav" [(activeId)]="tabModel.activeTab" class="nav-tabs">
              <li [ngbNavItem]="1">
                <a ngbNavLink (click)="onclickGoToTab(1)">Sources</a>
                <ng-template ngbNavContent>
                  <div class="ml5">
                    <div class="row">
                      <div class="col-12">
                          <div style="margin-bottom: 10px;">
                            <source-input [partner]="partner" [schedulers]="schedulers" [keyVaultProfiles]="keyVaultProfiles" [existingSchedulers]="existingSchedulers" (sourceUpdated)="onSourceUpdated($event)"></source-input>
                          </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </li>
              <li [ngbNavItem]="2">
                <a ngbNavLink (click)="onclickGoToTab(2)">Destinations</a>
                <ng-template ngbNavContent>
                  <div class="ml5">
                    <div class="row">
                      <div class="col-12">
                          <div style="margin-bottom: 10px;">
                            <destination-input [partner]="partner"></destination-input>
                          </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </li>
              <li [ngbNavItem]="3">
                <a ngbNavLink (click)="onclickGoToTab(3)">Parsers</a>
                <ng-template ngbNavContent>
                  <div class="ml5">
                    <div class="row">
                      <div class="col-12">
                          <div style="margin-bottom: 10px;">
                            <inboundparsers-input [partner]="partner"></inboundparsers-input>
                          </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </li>
              <li [ngbNavItem]="4">
                <a ngbNavLink (click)="onclickGoToTab(4)">Inbound => Canonical Mappings</a>
                <ng-template ngbNavContent>
                  <div class="ml5">
                    <div class="row">
                      <div class="col-12">
                          <div style="margin-bottom: 10px;">
                            <inboundToCanonicalMappingConnection-input [partner]="partner"></inboundToCanonicalMappingConnection-input>
                          </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </li>
              <li [ngbNavItem]="5">
                <a ngbNavLink (click)="onclickGoToTab(5)">Canonical => Outbound Mappings</a>
                <ng-template ngbNavContent>
                  <div class="ml5">
                    <div class="row">
                      <div class="col-12">
                          <div style="margin-bottom: 10px;">
                            <canonicalToOutboundMappingConnection-input [partner]="partner"></canonicalToOutboundMappingConnection-input>
                          </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </li>
              <li [ngbNavItem]="6">
                <a ngbNavLink (click)="onclickGoToTab(6)">Edi ExRef Pre-formatters</a>
                <ng-template ngbNavContent>
                  <div class="ml5">
                    <div class="row">
                      <div class="col-12">
                          <div style="margin-bottom: 10px;">
                            <ediPreFormatter-input [partner]="partner"></ediPreFormatter-input>
                          </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </li>
            </ul>
            <div [ngbNavOutlet]="nav" class="mt-2"></div>
          </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="save()">Save</button>
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('cancel')">Cancel</button>
</div>
