import { Injectable } from '@angular/core';
import { DropDownListModel } from 'src/app/models/dropdown-list-model';
import { Partner } from 'src/app/models/partner/partner';
import { Scheduler } from 'src/app/models/source/scheduler';
import { Source } from 'src/app/models/source/source';

@Injectable({
  providedIn: 'root',
})
export class SourceInputService {
    constructor() {}

    public validationTypes: DropDownListModel[] = [
      { value: '0', text: 'None' },
      { value: '1', text: 'Xml' },
      { value: '2', text: 'Xsd' },
      { value: '3', text: 'Json' },
    ];

    public getValidationTypeText(protocol: string) { 
      return this.validationTypes.find(x => x.value === protocol).text;
    }

    public getProtocolName(protocol: number) {
        let protocolName = '';
        switch(protocol) { 
          case 9: { 
            protocolName = 'HTTP';
             break; 
          }
          case 3: { 
            protocolName = 'FTP';
             break; 
          }
          case 8: { 
            protocolName = 'SFTP';
             break; 
          }
          case 1: { 
            protocolName = 'Service bus';
             break; 
          } 
          case 2: { 
            protocolName = 'Fileshare';
             break; 
          } 
          case 7: { 
            protocolName = 'MQ';
             break; 
          }  
          default: { 
             break; 
          } 
        }
    
        return protocolName;
    }

    public setFilteredSource(protocol: number, sources: Array<Source>) {
        let sourcesDDL = new Array<DropDownListModel>();
        
        let filteredSources = sources.filter(x => x.protocolType === protocol);

        filteredSources.forEach((x) => {
            let d: DropDownListModel = {
                value: x.id.toString(),
                text: x.name
              };
              sourcesDDL.push(d);
        });

        return sourcesDDL;
    }

    public getSchedulersArray(savedSchedulers: Array<Scheduler>, schedulers: Array<Scheduler>, partner: Partner) {
      let locSchedulers: Array<Scheduler> = new Array<Scheduler>();
      if(partner.id != -1) {
        let newSchedulers = new Array<Scheduler>();
        let existingSchedulers: Array<Scheduler> = savedSchedulers.filter(s => s.partnerId === +partner.id);
        if(schedulers != undefined) {
            newSchedulers = schedulers.filter(s => s.id === 0);
        }
        if(schedulers != undefined) {
            newSchedulers.forEach(s => {
              locSchedulers.push(s);
            });
        }
        
        existingSchedulers.forEach(s => {
          locSchedulers.push(s);
        });
      } else {
        locSchedulers = new Array<Scheduler>();
      }

      return locSchedulers
    }
}
