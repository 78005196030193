<div class="modal-header">
    <h4 class="modal-title">Edit source</h4>
</div>

<div class="modal-body">

    <div class="form-group row form-inline">
        <label for="protocolDDL" class="col-sm-2 col-form-label control-label mb10">Protocol</label>
        <div class="col-sm-6">
            <table>
                <tr>
                    <td>
                        <select dropdown id="protocolDDL" name="protocolDDL" disabled="true" class="form-select form-select-sm control-label" [(ngModel)]="sourceProtocol" (change)="selectProtocolChangeHandler($event)">
                            <option value="">--- Select protocol ---</option>
                            <option value="2">File Share</option>
                            <option value="3">FTP</option>
                            <option value="7">MQ</option>
                            <option value="1">Service bus</option>
                            <option value="8">SFTP</option>
                        </select>
                    </td>
                </tr>
            </table>                
        </div>
    </div>

    <!-- Scheduler -->
    <div *ngIf="sourceProtocol !== ''">
        <div class="form-group row mb5">
            <div class="col-sm-12">
                <span class="text-muted"><small>Name should be in the form "[partner] [Protocol Type] source" (example "One4AL MQ source"). If partner use different sources the name should be in the form "[partner] [Protocol Type] [Message Type] source" (example "One4AL MQ ProcessPurchaseOrder source").</small></span>
            </div>
        </div>
    
        <!-- scheduler.name -->
        <div class="form-group row mb5">
            <label for="schedulerName" class="col-sm-3 col-form-label">Name<span class="req-color" >*</span></label>
            <div class="col-sm-6">
                <input type="text" class="form-control" id="schedulerName" name="schedulerName" placeholder="Description" (change)="validateRequiredInputs()" [(ngModel)]="scheduler.name">
            </div>
        </div>
            
        <!-- scheduler.enabled -->
        <div class="form-group row mb5">
            <div class="col-sm-3 col-form-label"></div>
            <div class="col-sm-6">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="schedulerEnabled" value="" aria-label="..." [(ngModel)]="scheduler.enabled">
                    <label class="form-check-label" for="schedulerEnabled">
                        Enabled
                    </label>
                </div>
            </div>
        </div>
    
        <!-- scheduler.validationType -->
        <div class="form-group row form-inline mb10">
            <label for="validationTypesDDL" class="col-sm-3 col-form-label control-label">Validation type</label>
            <div class="col-sm-6">
                <select dropdown id="validationTypesDDL" name="validationTypesDDL" class="form-select form-select-sm control-label" [(ngModel)]="scheduler.validationType">
                    <option value="">--- Select protocol ---</option>
                    <option *ngFor="let item of sourceInputService.validationTypes" [value]="item.value">
                        {{item.text}}
                    </option>
                </select>
            </div>
        </div>

         <!-- scheduler.callbackUrl -->
        <div class="form-group row mb5">
            <label for="schedulerCallbackUrl" class="col-sm-3 col-form-label">Callback Url</label>
            <div class="col-sm-6">
                <input type="text" class="form-control" id="schedulerCallbackUrl" name="schedulerCallbackUrl" placeholder="Callback Url" [(ngModel)]="scheduler.callbackUrl">
            </div>
        </div>

        <!-- scheduler.callbackEnabled -->
        <div class="form-group row mb5">
            <div class="col-sm-3 col-form-label"></div>
            <div class="col-sm-6">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="callbackEnabled" value="" aria-label="..." [(ngModel)]="scheduler.callbackEnabled">
                    <label class="form-check-label" for="callbackEnabled">
                        Callback enabled
                    </label>
                </div>
            </div>
        </div>

        <div class="form-group row mb10">
            <label for="selectAuthentication" class="col-sm-3 col-form-label control-label">EDI Type </label>
            <div class="col-sm-6">
                <select dropdown (change)="selectEdiTypeChangeHandler($event)" id="selectEDIType" name="selectEDIType" class="form-select form-select-sm control-label" [(ngModel)]="ediType">
                    <option [value]="0">None</option>
                    <option *ngFor="let item of ediTypes" [value]="item.value">
                        {{item.text}}
                    </option>
                </select>
            </div>
        </div>

         <!-- IS simplified flow -->
        <div class="form-group row mb5">
            <div class="col-sm-3 col-form-label"></div>
            <div class="col-sm-6">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="isSimplified" value="" aria-label="..." [(ngModel)]="scheduler.isSimplified">
                    <label class="form-check-label" for="isSimplified">
                        Use for simplified flow
                    </label>
                </div>
            </div>
        </div>
    </div>
   
    <!-- Source -->
    <div>
        <source-fileshare-input *ngIf="sourceProtocol == '2' && sourceSharedFolderProfile" [sourceSharedFolderProfile]="sourceSharedFolderProfile"></source-fileshare-input>
        <source-mq-input *ngIf="sourceProtocol == '7' && sourceMqProfile" [sourceMqProfile]="sourceMqProfile"></source-mq-input>
        <source-sb-input *ngIf="sourceProtocol == '1' && sourceSbProfile" [sourceSbProfile]="sourceSbProfile" [sbKeyVaultProfiles]="keyVaultProfiles" (sbSecretUpdated)="onSbSecretUpdated($event)"></source-sb-input>
        <source-sftp-input *ngIf="sourceProtocol == '8' && sourceSftpProfile" [sourceSftpProfile]="sourceSftpProfile" [sbKeyVaultProfiles]="keyVaultProfiles" (sbSecretUpdated)="onSftpSecretUpdated($event)"></source-sftp-input>
        <source-ftp-input *ngIf="sourceProtocol == '3' && sourceFtpProfile" [sourceFtpProfile]="sourceFtpProfile" [sbKeyVaultProfiles]="keyVaultProfiles" (sbSecretUpdated)="onFtpSecretUpdated($event)"></source-ftp-input>
    </div>

</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" [disabled]="requiredInputSet" (click)="save()">Save</button>
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('cancel')">Cancel</button>
</div>
