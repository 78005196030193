<div class="form-group row mb5">
    <label for="sourceSharedFolderProfileServer" class="col-sm-3 col-form-label">Server<span class="req-color">*</span></label>
    <div class="col-sm-6">
        <input type="text" class="form-control" id="sourceSharedFolderProfileServer" name="sourceSharedFolderProfileServer" placeholder="Server" [(ngModel)]="sourceSharedFolderProfile.server">
    </div>
</div>

<div class="form-group row mb5">
    <label for="sourceSharedFolderProfilePath" class="col-sm-3 col-form-label">Path<span class="req-color">*</span></label>
    <div class="col-sm-6">
        <input type="text" class="form-control" id="sourceSharedFolderProfilePath" name="sourceSharedFolderProfilePath" placeholder="Path" [(ngModel)]="sourceSharedFolderProfile.path">
    </div>
</div>

<div class="form-group row mb5">
    <label for="sourceSharedFolderProfileFilter" class="col-sm-3 col-form-label">Filter<span class="req-color">*</span></label>
    <div class="col-sm-6">
        <input type="text" class="form-control" id="sourceSharedFolderProfileFilter" name="sourceSharedFolderProfileFilter" placeholder="Filter" [(ngModel)]="sourceSharedFolderProfile.filter">
    </div>
</div>

<div class="form-group row mb5">
    <label for="sourceSharedFolderProfileInputEncoding" class="col-sm-3 col-form-label">Input encoding<span class="req-color">*</span></label>
    <div class="col-sm-6">
        <input type="text" class="form-control" id="sourceSharedFolderProfileInputEncoding" name="sourceSharedFolderProfileInputEncoding" placeholder="Input encoding" [(ngModel)]="sourceSharedFolderProfile.inputEncoding">
    </div>
</div>

<div class="form-group row mb5">
    <label for="sourceSharedFolderProfileOutputEncoding" class="col-sm-3 col-form-label">Output encoding<span class="req-color">*</span></label>
    <div class="col-sm-6">
        <input type="text" class="form-control" id="sourceSharedFolderProfileOutputEncoding" name="sourceSharedFolderProfileOutputEncoding" placeholder="Output encoding" [(ngModel)]="sourceSharedFolderProfile.outputEncoding">
    </div>
</div>