import { Source } from "./source";

export class Scheduler {
    id: number;
    sourceId: number;
    name: string;
    enabled: boolean;
    partnerId: number;
    // inputEncoding: string;
    // outputEncoding: string;
    validationType: number;
    schema: string;
    callbackUrl: string;
    callbackEnabled: boolean;
    isSimplified: boolean;
    source: Source;
    createdBy: string;
    createTimestamp: string;
}