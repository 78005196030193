export const environment = {
    production: false,
    apiBaseUrl: 'https://al-api-goh-dev.azurewebsites.net/api/v1/',
    apiTrackingBaseUrl: 'https://al-api-goh-dev.azurewebsites.net/api/v2/',
    apiPrevBaseUrl: 'https://al-api-goh-dev.azurewebsites.net/api/v1/',
    adminToolClientId: 'fb74dec9-62c6-4585-81c2-6279262e51f8',
    adminToolTenentId: 'ed5d5f47-52dd-48af-90ca-f7bd83624eb9',
    env: 'dev',
    prevenv: 'dev',
    d365EnricherHost: 'https://api-alfalaval-dev.azure-api.net/api/v1/d365fo/D365FOLookupService',
    httpDestinationDefaultQueue: 'goh.transfer.send.http'
};
