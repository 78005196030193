import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { generate } from 'rxjs';
import { Scheduler } from 'src/app/models/source/scheduler';
import { MDSourceInputService } from './md-source-input.service';
import { ErrorModel } from 'src/app/models/error-model';
import { ErrorParserService } from 'src/app/common-services/error-parser.service';

@Component({
  selector: 'delete-source-input', // TODO: Is selector needed for modal?
  templateUrl: './delete-source-modal.component.html',
})
export class DeleteSourceModalComponent {
    @Input() scheduler: Scheduler;

    constructor(public activeModal: NgbActiveModal, public sourceInputService: MDSourceInputService, private errorParserService: ErrorParserService) {}

    public errorModel: ErrorModel = null;

    public delete() {
        this.errorModel = null;
        this.sourceInputService.deleteScheduler(this.scheduler).subscribe(
              {
                  next: (result) => {
                       this.activeModal.close('save');
                  },
                  error: (error) => {
                    this.errorModel = this.errorParserService.extractErrors(error);
                    console.error(error);
                  }
               }
          );
      }
}
