import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UtilService } from 'src/app/common-services/util.service';
import { KeyVaultProfile } from 'src/app/models/key-vault-profile';
import { ConfDestConnection } from '../../../../models/destination/conf-dest-connection';
import { ConfDestination } from '../../../../models/destination/conf-destination';
import { DestinationHttpprofile } from '../../../../models/destination/destination-http-profile';
import { DestinationMqprofile } from '../../../../models/destination/destination-mq-profile';
import { DestinationSbprofile } from '../../../../models/destination/destination-sb-profile';
import { DestinationSharedFolderProfile } from '../../../../models/destination/destination-shared-folder-profile';
import { DropDownListModel } from '../../../../models/dropdown-list-model';
import { EntityMap } from '../../../../models/entity-map';
import { DestinationInputService } from './destination-input.service';
import { MDDestinationService } from 'src/app/master-data/partners/child-components/destinations/md-destination.service';
import { FormArray, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'destination-input',
  templateUrl: './destination-input.component.html'
})
export class DestinationInputComponent implements OnInit {
  @Input() confDestConnections: Array<ConfDestConnection>;
  @Input() keyVaultProfiles: Array<KeyVaultProfile>;
  //@Input() entityMap: EntityMap; // For constructing destination connection name
  @Input() outboundPartnerId: number;
  @Input() entityMapId: number;
  @Input() confDestinations: Array<ConfDestination>;
  @Output() destinationUpdated = new EventEmitter<any>();

  @Input() formGroupName: string;
  public confDestinationForm: FormGroup;

  public destinationHttpprofile: DestinationHttpprofile;
  public destinationMqprofile: DestinationMqprofile;
  public destinationSbprofile: DestinationSbprofile;
  public destinationSharedFolderProfile: DestinationSharedFolderProfile;

  public confDestinationsDDL: Array<DropDownListModel>;
  public destinationProtocol: string = '';
  public confDestConnectionEnabled: boolean = false;
  public removeBom: boolean = false;
  public isPrimary: boolean = true;
  public httpSecretDdl: DropDownListModel;
  public selectedHttpKeyVaultProfile: KeyVaultProfile;
  public sbSecretDdl: DropDownListModel;
  public displayStyle: string = 'none';
  public viewConfigData: boolean = true;
  public useExistingDestination: boolean = true;
  public selectedDestinationValue: string = '-1';
  public protocolHeaderText: string = '';
  public isEdit: boolean = false;
  public confDestConnectionEditId: number;
  public outboundPartnerDestinations: Array<ConfDestination>;
  form!: FormGroup;
  
  constructor(private rootFormGroup: FormGroupDirective, public destInputService: DestinationInputService, public utilService: UtilService, private destinationService: MDDestinationService) {}
  
  ngOnInit(): void {
    this.form = this.rootFormGroup.form;

    this.getPartnerDestinations(this.outboundPartnerId);
  }

  private getPartnerDestinations(partnerId: number) {
    // this.showSpinner = true;
    this.destinationService.getPartnerDestinations(partnerId).subscribe({
        next: (destinations) => {
            this.outboundPartnerDestinations = destinations;
        },
        error: (error) => {
            // this.showSpinner = false;
            // this.displayErrorMessage(error);
        }
    });
  }

  selectChangeHandler(event: any) {
  }

  public openProtocolModelWindow() {

  }

  public removeConfDestConnection(confDestConnection: ConfDestConnection) {
    this.confDestConnections.forEach( (item, index) => {
      if(item === confDestConnection) this.confDestConnections.splice(index,1);
    });
  }
  
  // this is executed when clicking the select button
  public addDestination() {
    if(this.useExistingDestination) {
      let confDestConnection: ConfDestConnection = new ConfDestConnection();

      confDestConnection.id = 0;
      confDestConnection.entityMapId = this.entityMapId;
      confDestConnection.destinationId = +this.selectedDestinationValue;
      confDestConnection.confDestination = this.outboundPartnerDestinations.find(x => x.id === +this.selectedDestinationValue);        
      confDestConnection.enabled = this.confDestConnectionEnabled;
      confDestConnection.removeBom = this.removeBom;
      confDestConnection.description = confDestConnection.confDestination.description;
      confDestConnection.confDestination.type = this.isPrimary === true ? 0 : 1;
      this.confDestConnections.push(confDestConnection);

      // Add destination to 
      // Emit this to parent as the below does not update parent!
      let newDestination = new FormGroup({
        id: new FormControl(confDestConnection.id),
        entityMapId: new FormControl(confDestConnection.entityMapId),
        destinationId: new FormControl(confDestConnection.destinationId),
        enabled: new FormControl(confDestConnection.enabled),
        removeBom: new FormControl(confDestConnection.removeBom),
        description: new FormControl(confDestConnection.description),
        confDestination: new FormGroup({
          type: new FormControl(confDestConnection.confDestination.type),
        })
      });
      this.destinationUpdated.emit(newDestination);
    }
 }

  public destinationEnabledOnChange(confDestConnection: ConfDestConnection) {

  }
}
