<div class="form-group row mb5">
    <label for="sourceSbProfileNamespace" class="col-sm-3 col-form-label">Namespace<span class="req-color">*</span></label>
    <div class="col-sm-6">
        <input type="text" class="form-control" id="sourceSbProfileNamespace" name="sourceSbProfileNamespace" placeholder="Namespace" [(ngModel)]="sourceSbProfile.namespace" (change)="onTextChange($event)">
    </div>
</div>

<div class="form-group row mb5">
    <label for="sourceSbProfileQueue" class="col-sm-3 col-form-label">Queue<span class="req-color">*</span></label>
    <div class="col-sm-6">
        <input type="text" class="form-control" id="sourceSbProfileQueue" name="sourceSbProfileQueue" placeholder="Queue" [(ngModel)]="sourceSbProfile.queue" (change)="onTextChange($event)">
    </div>
</div>
<!-- Use RegEx -->
<div class="form-group row mb5">
    <label for="sourceSbProfileUseManagedIdentity" class="col-sm-3 col-form-label">Use ManagedIdentity</label>
    <div class="col-sm-6">
        <div class="form-check">
            <input type="checkbox" class="form-check-input" id="sourceSbProfileUseManagedIdentity" value="" name="sourceSbProfileUseManagedIdentity" placeholder="UseManagedIdentity" [(ngModel)]="sourceSbProfile.useManagedIdentity" (change)="onUseManagedIdentityChange($event)">
        </div>
    </div>
</div>

<!-- Selection -->
<div *ngIf="sourceSbProfile.useManagedIdentity == false">
    <div class="row" *ngIf="sourceSbProfile.useManagedIdentity == false">
        <div class="col-sm-3"></div>
        <div class="col-sm-6">
            <div class="form-check">
                <input class="form-check-input" type="radio" name="keyVaultOptions1" [value]="1" [(ngModel)]="keyVaultUpdateType" id="keyVaultOptions1">
                <label class="form-check-label" for="keyVaultOptions1">
                    Use existing key vault secret
                </label>
            </div>
            <div class="form-check">
                <input class="form-check-input" type="radio" name="keyVaultOptions2" [value]="2" [(ngModel)]="keyVaultUpdateType" id="keyVaultOptions2" checked>
                <label class="form-check-label" for="keyVaultOptions2">
                    Add key vault secret
                </label>
            </div>
            <div class="form-check" *ngIf="sourceSbProfile.keyVaultProfile != null">
                <input class="form-check-input" type="radio" name="keyVaultOptions3" [value]="3" [(ngModel)]="keyVaultUpdateType" id="keyVaultOptions3">
                <label class="form-check-label" for="keyVaultOptions3">
                    Update key vault secret
                </label>
            </div>
        </div>
    </div>  

    <div class="form-group row mb5">
        <label for="sourceSbProfileSharedAccessKeyName" class="col-sm-3 col-form-label">SharedAccessKeyName<span class="req-color">*</span></label>
        <div class="col-sm-6">
            <input type="text" class="form-control" id="sourceSbProfileSharedAccessKeyName" name="sourceSbProfileSharedAccessKeyName" placeholder="SharedAccessKeyName" [(ngModel)]="sourceSbProfile.sharedAccessKeyName" (change)="onTextChange($event)">
        </div>
    </div>

    <div class="form-group row mb5" *ngIf="keyVaultUpdateType === 2">
        <label for="systemName" class="col-sm-3 col-form-label">System name <i class="fa fa-info-circle alfalaval-blue-color" aria-hidden="true" placement="top" ngbTooltip="The secret that will store the ServiceBus key has the form of al-goh-servicebus-auth-in-{system name}-key. The value you provide in the System name input will be used to construct the secret name."></i></label>
        <div class="col-sm-6">
            <input type="text" class="form-control" id="systemName" name="systemName" placeholder="System name" [(ngModel)]="systemName" (change)="onSystemNameChange($event)">
        </div>
    </div>
    <!-- sourceAccesskey -->
    <div class="form-group row mb5" *ngIf="keyVaultUpdateType === 2 || keyVaultUpdateType === 3">
        <label for="destinationPassword" class="col-sm-3 col-form-label">Access key</label>
        <div class="col-sm-6">
            <!-- Here connectionString is hijacked just to pass key to server side-->
            <!-- TODO we need a property to send the key to serverside!!!!!!!!!!!!! -->
            <input type="password" class="form-control" id="sourceAccesskey" name="sourceAccesskey" placeholder="Password" [(ngModel)]="sourceSbProfile.sharedAccessKey">
        </div>
    </div>
    <!-- Key vault secret -->
    <div class="form-group row" *ngIf="sbKeyVaultProfiles && keyVaultUpdateType === 1">
        <label for="keyVaultSecretDDL" class="col-sm-3 col-form-label control-label">Key vault secret</label>
        <div class="col-sm-6">
            <select dropdown (change)="selectKeyVaultProfileChangeHandler($event)" id="keyVaultSecretDDL" name="keyVaultSecretDDL" class="form-select form-select-sm control-label" [(ngModel)]="sourceSbProfile.keyVaultProfileId">
                <option value="-1">--- Select secret ---</option>
                <option *ngFor="let item of sbKeyVaultProfiles" [value]="item.id">
                    {{item.name}}
                </option>
            </select>
        </div>
    </div>
</div>