import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { generate } from 'rxjs';
import { DropDownListModel } from 'src/app/models/dropdown-list-model';
import { KeyVaultProfile } from 'src/app/models/key-vault-profile';
import { Scheduler } from 'src/app/models/source/scheduler';
import { Source } from 'src/app/models/source/source';
import { UtilService } from 'src/app/common-services/util.service';
import { SourceSbProfile } from 'src/app/models/source/source-sb-profile';
import { SourceMqProfile } from 'src/app/models/source/source-mq-profile';
import { MDSourceInputService } from './md-source-input.service';
import { SourceSharedFolderProfile } from 'src/app/models/source/source-shared-folder-profile';
import { MessageTypeUpdateData } from 'src/app/models/message-type-update-data';
import { Partner } from 'src/app/models/partner/partner';
import { MsalService } from '@azure/msal-angular';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
 import { AddSourceModalComponent } from './add-source-model.component';
import { DeleteSourceModalComponent } from './delete-source-modal.component';
import { EditSourceModalComponent } from './edit-source-model.component';

@Component({
  selector: 'source-input',
  templateUrl: './md-source-input.component.html'
})
export class MDSourceInputComponent implements OnInit {
    @Input() partner: Partner;
    @Input() messageType: MessageTypeUpdateData;
    @Input() schedulers: Array<Scheduler>;
    @Input() existingSchedulers: Array<Scheduler>;
    @Input() keyVaultProfiles: Array<KeyVaultProfile>;
    @Output() sourceUpdated = new EventEmitter<any>();

    // TODO string is sent as value and need to be sent back to keep the state!!!!!!!
    public sourceProtocol: string;
    // @Output() sourceUpdated = new EventEmitter<any>();

    public sourceSbProfile: SourceSbProfile;
    public sourceMqProfile: SourceMqProfile;
    public sourceSharedFolderProfile: SourceSharedFolderProfile;

    public sources: Array<Source> = new Array<Source>();
    // public scheduler: Scheduler;
    public schedulerToAdd: Scheduler;
    public sbSources: Array<Source>;
    public mqSources: Array<Source>;
    public fileshareSources: Array<Source>;    
    public displayStyle: string = 'none';
    public viewConfigData: boolean = true;
    public isEdit: boolean = false; // Edit??????
    public protocolHeaderText: string = '';
    public requiredInputSet: boolean = false; // TODO Add validation of required input
    public useExistingSource: boolean = false;
    public sourcesDDL: Array<DropDownListModel>;
    public selectedSourceValue: string = '-1';
    public schedulerEnabled: boolean = false;
    public sbSecretDdl: DropDownListModel;
    public isAdmin: boolean = false;
    public isOnboarder: boolean = false;

    constructor(public utilService: UtilService, public sourceInputService: MDSourceInputService, private authService: MsalService, private modalService: NgbModal) {}

    ngOnInit(): void {
        let allAccounts = this.authService.instance.getAllAccounts();
        if (allAccounts.length > 0) {
            let account = allAccounts[0];
            this.isAdmin = account.idTokenClaims.roles.find(x => x == 'GOH.AdminTool.Admin') == undefined ? false : true;
            this.isOnboarder = account.idTokenClaims.roles.find(x => x == 'GOH.AdminTool.Onboarder') == undefined ? false : true;
        }

        let testschedulers = this.schedulers;
        // TODO Is this used????????
        // this.sbSources = this.sources.filter(x => x.protocolType === 1);
        // this.mqSources = this.sources.filter(x => x.protocolType === 7);
        // this.fileshareSources = this.sources.filter(x => x.protocolType === 2);
        
        // If this is new configuration we need to get the configured sources for this partner
        // If schedulers are already set we should not set schedulers from saved schedulers
        //this.schedulers = this.sourceInputService.getSchedulersArray(this.existingSchedulers, this.schedulers, this.partner);
    }

    public onSbSecretUpdated(ddl: DropDownListModel) {
        this.sbSecretDdl = Object.assign({}, ddl);       
    }

    public openAddSourceModal() {
        const modalRef = this.modalService.open(AddSourceModalComponent, { size: 'size_40' });

        let scheduler = new Scheduler();
        scheduler.validationType = 0;
        scheduler.enabled = false;
        scheduler.sourceId = 0;
        scheduler.source = new Source();
        scheduler.partnerId = this.partner.id;
        scheduler.isSimplified = false;

        modalRef.componentInstance.scheduler = scheduler;
        
        modalRef.result.then((result) => {
            // Refresh grid after save
            if(result === 'save') {
                this.sourceUpdated.emit(this.schedulers);
                //this.getData(1, 10);
            }
        }, (reason) => {
            // User clicked outside modal window => do nothing (same as cancel)            
        });
    }

    public cancel() {
        this.displayStyle = "none";
    }
         
    public removeScheduler(scheduler: Scheduler) {
        const modalRef = this.modalService.open(DeleteSourceModalComponent, { size: 'size_40' });
        modalRef.componentInstance.scheduler = scheduler;
        modalRef.result.then((result) => {
            // Refresh grid after save
            if(result === 'save') {
                this.sourceUpdated.emit(this.schedulers);
                //this.getData(1, 10);
            }
        }, (reason) => {
            // User clicked outside modal window => do nothing (same as cancel)            
        });

        // TODO Do we need the below???
        // this.schedulers.forEach( (item, index) => {
        //     if(item === scheduler) this.schedulers.splice(index,1);
        // });

        // let testtest = scheduler;
    }

    public editSource(scheduler: Scheduler) {
        // Only need to update profile table in this case!!!
        const modalRef = this.modalService.open(EditSourceModalComponent, { size: 'size_40' });
        modalRef.componentInstance.scheduler = scheduler;
        modalRef.componentInstance.sourceProtocol = scheduler.source.protocolType.toString();

        modalRef.result.then((result) => {
            // Refresh grid after save
            if(result === 'save') {
                this.sourceUpdated.emit(this.schedulers);
                //this.getData(1, 10);
            }
        }, (reason) => {
            // User clicked outside modal window => do nothing (same as cancel)            
        });
    }

    public schedulerEnabledOnChange(scheduler: Scheduler) {
        // No need to emit?
        // this.sourceUpdated.emit(this.schedulers);
    }
}
