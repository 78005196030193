<!-- Host -->
<div class="form-group row mb5">
    <label for="sourceSftpProfileHost" class="col-sm-3 col-form-label">Host<span class="req-color">*</span></label>
    <div class="col-sm-6">
        <input type="text" class="form-control" id="sourceSftpProfileHost" name="sourceSftpProfileHost" placeholder="Host" [(ngModel)]="sourceSftpProfile.host" (change)="onTextChange($event)">
    </div>
</div>
<!-- Port -->
<div class="form-group row mb5">
    <label for="sourceSftpProfilePort" class="col-sm-3 col-form-label">Port<span class="req-color">*</span></label>
    <div class="col-sm-6">
        <input type="text" class="form-control" id="sourceSftpProfilePort" name="sourceSftpProfilePort" placeholder="Port" [(ngModel)]="sourceSftpProfile.port" (change)="onTextChange($event)">
    </div>
</div>
<!-- Path -->
<div class="form-group row mb5">
    <label for="sourceSftpProfilePath" class="col-sm-3 col-form-label">Path<span class="req-color">*</span></label>
    <div class="col-sm-6">
        <input type="text" class="form-control" id="sourceSftpProfilePath" name="sourceSftpProfilePath" placeholder="Path" [(ngModel)]="sourceSftpProfile.path" (change)="onTextChange($event)">
    </div>
</div>
<!-- Archive path -->
<div class="form-group row mb5">
    <label for="sourceSftpProfileArchivePath" class="col-sm-3 col-form-label">Archive path<span class="req-color">*</span></label>
    <div class="col-sm-6">
        <input type="text" class="form-control" id="sourceSftpProfileArchivePath" name="sourceSftpProfileArchivePath" placeholder="Archive path" [(ngModel)]="sourceSftpProfile.archivePath" (change)="onTextChange($event)">
    </div>
</div>
<!-- Encoding -->
<div class="form-group row mb5">
    <label for="sourceSftpProfileEncoding" class="col-sm-3 col-form-label">Encoding<span class="req-color">*</span></label>
    <div class="col-sm-6">
        <input type="text" class="form-control" id="sourceSftpProfileEncoding" name="sourceSftpProfileEncoding" placeholder="Encoding" [(ngModel)]="sourceSftpProfile.encoding" (change)="onTextChange($event)">
    </div>
</div>
<!-- Use RegEx -->
<div class="form-group row mb5">
    <div class="col-sm-3 col-form-label"></div>
    <div class="col-sm-6">
        <div class="form-check">
            <input class="form-check-input" type="checkbox" id="sourceSftpProfileUseRegEx" value="" aria-label="..." [(ngModel)]="sourceSftpProfile.useRegEx">
            <label class="form-check-label" for="sourceSftpProfileUseRegEx">
                Use RegEx
            </label>
        </div>
    </div>
</div>
<!-- FilterOrRegEx -->
<div class="form-group row mb5">
    <label for="sourceSftpProfileFilterOrRegEx" class="col-sm-3 col-form-label">Filter/RegEx<span class="req-color">*</span></label>
    <div class="col-sm-6">
        <input type="text" class="form-control" id="sourceSftpProfileFilterOrRegEx" name="sourceSftpProfileFilterOrRegEx" placeholder="Filter/RegEx" [(ngModel)]="sourceSftpProfile.filterOrRegEx" (change)="onTextChange($event)">
    </div>
</div>
<!-- Delete -->
<div class="form-group row mb5">
    <div class="col-sm-3 col-form-label"></div>
    <div class="col-sm-6">
        <div class="form-check">
            <input class="form-check-input" type="checkbox" id="sourceSftpProfileDelete" value="" aria-label="..." [(ngModel)]="sourceSftpProfile.delete">
            <label class="form-check-label" for="sourceSftpProfileDelete">
                Delete
            </label>
        </div>
    </div>
</div>

<!-- Selection -->
<div class="row mb5">
    <div class="col-sm-3"></div>
    <div class="col-sm-6">
        <div class="form-check">
            <input class="form-check-input" type="radio" name="keyVaultOptions1" [value]="1" [(ngModel)]="keyVaultUpdateType" id="keyVaultOptions1">
            <label class="form-check-label" for="keyVaultOptions1">
                Use existing key vault secret
            </label>
        </div>        
        <div class="form-check">
            <input class="form-check-input" type="radio" name="keyVaultOptions2" [value]="2" [(ngModel)]="keyVaultUpdateType" id="keyVaultOptions2" checked>
            <label class="form-check-label" for="keyVaultOptions2">
                Add key vault secret
            </label>
        </div>
        <div class="form-check" *ngIf="sourceSftpProfile.keyVaultProfile != null">
            <input class="form-check-input" type="radio" name="keyVaultOptions3" [value]="3" [(ngModel)]="keyVaultUpdateType" id="keyVaultOptions3">
            <label class="form-check-label" for="keyVaultOptions3">
                Update key vault secret
            </label>
        </div>
    </div>
</div>
<!-- Username -->
<div class="form-group row mb5">
    <label for="sourceSftpProfileUsername" class="col-sm-3 col-form-label">Username<span class="req-color">*</span></label>
    <div class="col-sm-6">
        <input type="text" class="form-control" id="sourceSftpProfileUsername" name="sourceSftpProfileUsername" placeholder="Username" [(ngModel)]="sourceSftpProfile.userName" (change)="onTextChange($event)">
    </div>
</div>
<!-- Key vault secret -->
<div class="form-group row mb5" *ngIf="sbKeyVaultProfiles && keyVaultUpdateType === 1">
    <label for="keyVaultSecretDDL" class="col-sm-3 col-form-label control-label">Key vault secret</label>
    <div class="col-sm-6">
        <select dropdown (change)="selectKeyVaultProfileChangeHandler($event)" id="keyVaultSecretDDL" name="keyVaultSecretDDL" class="form-select form-select-sm control-label" [(ngModel)]="sourceSftpProfile.keyVaultProfileId">
            <option value="-1">--- Select secret ---</option>
            <option *ngFor="let item of sbKeyVaultProfiles" [value]="item.id">
                {{item.name}}
            </option>
        </select>
    </div>
</div>
<!-- Password -->
<div class="form-group row mb5" *ngIf="keyVaultUpdateType === 2 || keyVaultUpdateType === 3">
    <label for="destinationPassword" class="col-sm-3 col-form-label">Password</label>
    <div class="col-sm-6">
        <input type="password" class="form-control" id="destinationPassword" name="destinationPassword" placeholder="Password" [(ngModel)]="sourceSftpProfile.passPhrase">
    </div>
</div>
<!-- System name -->
<div class="form-group row mb5" *ngIf="keyVaultUpdateType === 2">
    <label for="systemName" class="col-sm-3 col-form-label">System name <i class="fa fa-info-circle alfalaval-blue-color" aria-hidden="true" placement="top" ngbTooltip="The secret that will store the SFTP password has the form of al-goh-sftp-auth-in-{system name}-password. The value you provide in the System name input will be used to construct the secret name."></i></label>
    <div class="col-sm-6">
        <input type="text" class="form-control" id="systemName" name="systemName" placeholder="System name" [(ngModel)]="systemName" (change)="onSystemNameChange($event)">
    </div>
</div>

<!-- 
authenticationTypeId: number;
-->