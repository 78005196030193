import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { generate } from 'rxjs';
import { SourceSharedFolderProfile } from 'src/app/models/source/source-shared-folder-profile';

@Component({
  selector: 'source-fileshare-input',
  templateUrl: './source-fileshare.component.html',  
})
export class SourceFileshareComponent {
    @Input() sourceSharedFolderProfile: SourceSharedFolderProfile;
}
