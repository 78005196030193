import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MasterDataPartnersComponent } from './md-partners.component';
import { PartnerService } from './partner.service';
import { AppPartnerComponent } from './child-components/add-partner.component';
import { EditPartnerComponent } from './child-components/edit-partner.component';
import { MDSourceInputComponent } from './child-components/source/md-source-input.component';
import { MDSourceInputService } from './child-components/source/md-source-input.service';
import { MDInboundParsersComponent } from './child-components/parsers/md-inboundparsers-input.component';
import { AddSourceModalComponent } from './child-components/source/add-source-model.component';
import { SourceMqComponent } from './child-components/source/profiles/source-mq.component';
import { SourceSbComponent } from './child-components/source/profiles/source-sb.component';
import { SourceInputService } from './child-components/source/source-input.service';
import { SourceSftpComponent } from './child-components/source/profiles/source-sftp.component';
import { DeleteSourceModalComponent } from './child-components/source/delete-source-modal.component';
import { EditSourceModalComponent } from './child-components/source/edit-source-model.component';
import { AddParserModalComponent } from './child-components/parsers/add-parser-modal.component';
import { DeleteParserConnectionModalComponent } from './child-components/parsers/delete-parser-connection-modal.component';
import { EditParserModalComponent } from './child-components/parsers/edit-parser-modal.component';
import { UtilService } from 'src/app/common-services/util.service';
import { MDDestinationInputComponent } from './child-components/destinations/md-destination-input.component';
import { AddDestinationModalComponent } from './child-components/destinations/add-destination-model.component';
import { EditInboundMappingConnectionModalComponent } from './child-components/inboundMapping/edit-inboundToCanonicalMappingConnection-modal.component';
import { AddInboundMappingConnectionModalComponent } from './child-components/inboundMapping/add-inboundToCanonicalMappingConnection-modal.component';
import { MDInboundMappingConnectionInputComponent } from './child-components/inboundMapping/md-inboundToCanonicalMappingConnection-input.component';
import { EditOutboundMappingConnectionModalComponent } from './child-components/outboundMapping/edit-canonicalToOutboundMappingConnection-modal.component';
import { AddOutboundMappingConnectionModalComponent } from './child-components/outboundMapping/add-canonicalToOutboundMappingConnection-modal.component';
import { MDOutboundMappingConnectionInputComponent } from './child-components/outboundMapping/md-canonicalToOutboundMappingConnection-input.component';
import { AddEdiPreFormatterModalComponent } from './child-components/ediPreFormatter/add-ediPreFormatter-modal.component';
import { EditEdiPreFormatterModalComponent } from './child-components/ediPreFormatter/edit-ediPreFormatter-modal.component';
import { MDEdiPreFormatterInputComponent } from './child-components/ediPreFormatter/md-ediPreFormatter-input.component';
import { HttpProfileComponent } from './child-components/destinations/profiles/http-profile.component';
import { SbProfileComponent } from './child-components/destinations/profiles/sb-profile.component';
import { SftpProfileComponent } from './child-components/destinations/profiles/sftp-profile.component';
import { MqProfileComponent } from './child-components/destinations/profiles/mq-profile.component';
import { FileshareProfileComponent } from './child-components/destinations/profiles/fileshare-profile.component';
import { KeyVaultService } from 'src/app/common-services/keyvault.service';
import { DeleteDestinationComponent } from './child-components/destinations/delete-destination.component';
import { HttpProfileService } from './child-components/destinations/profiles/http-profile.service';
import { CommonComponentsModule } from 'src/app/common-components/common-components.module';
import { SourceViewTableRowModule } from 'src/app/common-components/source-vew/source-view-table-data.module';
import { SourceFileshareComponent } from './child-components/source/profiles/source-fileshare.component';
import { SourceViewTableRowComponent } from 'src/app/common-components/source-vew/source-view-table-data.component';
import { SourceFtpComponent } from './child-components/source/profiles/source-ftp.component';
import { FtpProfileComponent } from './child-components/destinations/profiles/ftp-profile.component';
import { EditDestinationModalComponent } from './child-components/destinations/edit-destination-model.component';
import { ErrorDisplayModule } from 'src/app/common-components/error-display/error-display.module';

@NgModule({
  imports:      [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule, // not used?
    BrowserAnimationsModule,// not used?
    NgbModule,
    CommonComponentsModule,
    SourceViewTableRowModule,
    ErrorDisplayModule
  ],
  providers:    [ PartnerService, MDSourceInputService, SourceInputService, UtilService, KeyVaultService, HttpProfileService ],
  declarations: [
    MasterDataPartnersComponent,
    AppPartnerComponent,
    EditPartnerComponent,
    MDSourceInputComponent,
    MDDestinationInputComponent,
    AddDestinationModalComponent,
    EditDestinationModalComponent,
    HttpProfileComponent,
    SbProfileComponent,
    MqProfileComponent,
    SftpProfileComponent,
    FtpProfileComponent,
    FileshareProfileComponent,
    MDInboundParsersComponent,
    AddSourceModalComponent,
    SourceFileshareComponent,
    SourceMqComponent,
    SourceSbComponent,
    SourceSftpComponent,
    SourceFtpComponent,
    DeleteSourceModalComponent,
    EditSourceModalComponent,
    AddParserModalComponent,
    DeleteParserConnectionModalComponent,
    EditParserModalComponent,
    // RowDisplayComponent,
    DeleteDestinationComponent,
    AddInboundMappingConnectionModalComponent,
    EditInboundMappingConnectionModalComponent,
    MDInboundMappingConnectionInputComponent,
    AddOutboundMappingConnectionModalComponent,
    EditOutboundMappingConnectionModalComponent,
    MDOutboundMappingConnectionInputComponent,
    AddEdiPreFormatterModalComponent,
    EditEdiPreFormatterModalComponent,
    MDEdiPreFormatterInputComponent
  ]
})
export class MasterDataPartnersModule { }
