import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { KeyVaultProfile } from 'src/app/models/key-vault-profile';
import { DestinationSbprofile } from '../../../../../models/destination/destination-sb-profile';
import { KeyVaultService } from 'src/app/common-services/keyvault.service';
import { SbProfileService } from './sb-profile.service';
import { FormGroup, FormGroupDirective } from '@angular/forms';

@Component({
    selector: "sb-profile",
    templateUrl: "./sb-profile.component.html"
})
export class SbProfileComponent implements OnInit {
    @Input() formGroupName: string;
    @Input() isEdit: boolean;

    public destinationSbprofileForm: FormGroup;
    public keyVaultProfiles: Array<KeyVaultProfile>;
    public sbProfiles: Array<DestinationSbprofile>;

    constructor(private keyVaultService: KeyVaultService, private sbProfileService: SbProfileService, private rootFormGroup: FormGroupDirective) {}

    get form() { return this.destinationSbprofileForm.controls; }

    ngOnInit(): void {
        this.destinationSbprofileForm = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;

        this.sbProfileService.getAllSbProfiles().subscribe({
            next: (result) => {
                this.sbProfiles = result;
            },
            // TODO Add better error hanlding to present the issue!
            error: (error) => {
                //this.showSpinner = false;
                console.error(error);
            }
        });

        this.keyVaultService.getKeyVaultProfiles().subscribe({
            next: (result) => {
                this.keyVaultProfiles = result;
            },
            // TODO Add better error hanlding to present the issue!
            error: (error) => {
                //this.showSpinner = false;
                console.error(error);
            }
        });
    }

    public handleDestOptionsChange(event: any) {
        this.sbProfileService.setupRequiredValidation(this.destinationSbprofileForm);
    }
}