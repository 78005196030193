import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DropDownListModel } from 'src/app/models/dropdown-list-model';
import { KeyVaultProfile } from 'src/app/models/key-vault-profile';
import { SourceFtpProfile } from 'src/app/models/source/source-ftp-profile';


@Component({
  selector: 'source-ftp-input',
  templateUrl: './source-ftp.component.html'
})
export class SourceFtpComponent implements OnInit {
    @Input() sourceFtpProfile: SourceFtpProfile;
    @Input() sbKeyVaultProfiles: Array<KeyVaultProfile>;
    @Output() sbSecretUpdated = new EventEmitter<any>();
    @Output() sbProfileUpdated = new EventEmitter<any>();

    public systemName: string = '';
    public selectedKeyVaultProfile: string = '-1';
    public selectedKeyVaultProfileValue: string;
    public selectedKeyVaultProfileText: string;
    public useExistingKeyVaultValue: boolean = true;
    public keyVaultUpdateType: number = 1;
    
    ngOnInit(): void {
    }

    // TODO If not needed => Remove!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    public onTextChange(event: any) {        
    }
   
    private getSbUpdateData() {      
    }

    selectSBSourceChangeHandler(event: any) {
    }

    selectKeyVaultProfileChangeHandler(event: any) {
        this.selectedKeyVaultProfileValue = event.target.value;
        this.selectedKeyVaultProfileText = event.target[event.target.selectedIndex].text;

        let ddl: DropDownListModel = new DropDownListModel();
        ddl.value = event.target.value;
        ddl.text = event.target[event.target.selectedIndex].text;
        
        // When using a existing keyvault profile the payload need to be cleared as only the id is needed.
        this.sourceFtpProfile.keyVaultProfile = null;
    }

    public onSystemNameChange(event: any) {
        let keyVaultProfile = new KeyVaultProfile();
        keyVaultProfile.id = 0;
        keyVaultProfile.keyVaultName = null; // Will be set in API Controller
        this.sourceFtpProfile.keyVaultProfileId = 0;
        keyVaultProfile.secretName = `al-goh-ftp-auth-in-${this.systemName}-password`
        keyVaultProfile.name = `${this.systemName} FTP Inbound Secret`
        this.sourceFtpProfile.keyVaultProfile = keyVaultProfile;
    }
}
