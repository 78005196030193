<div class="form-group row mb5">
    <label for="sourceMQProfileHost" class="col-sm-3 col-form-label">Host<span class="req-color">*</span></label>
    <div class="col-sm-6">
        <input type="text" class="form-control" id="sourceMQProfileHost" name="sourceMQProfileHost" placeholder="Host" [(ngModel)]="sourceMqProfile.host">
    </div>
</div>

<div class="form-group row mb5">
    <label for="sourceMQProfilePort" class="col-sm-3 col-form-label">Port<span class="req-color">*</span></label>
    <div class="col-sm-6">
        <input type="text" class="form-control" id="sourceMQProfilePort" name="sourceMQProfilePortt" placeholder="Port" [(ngModel)]="sourceMqProfile.port">
    </div>
</div>

<div class="form-group row mb5">
    <label for="sourceMQProfileChannel" class="col-sm-3 col-form-label">Channel<span class="req-color">*</span></label>
    <div class="col-sm-6">
        <input type="text" class="form-control" id="sourceMQProfileChannel" name="sourceMQProfileChannel" placeholder="Channel" [(ngModel)]="sourceMqProfile.channel">
    </div>
</div>

<div class="form-group row mb5">
    <label for="sourceMQProfileQm" class="col-sm-3 col-form-label">Queue manager<span class="req-color">*</span></label>
    <div class="col-sm-6">
        <input type="text" class="form-control" id="sourceMQProfileQm" name="sourceMQProfileQm" placeholder="Queue manager" [(ngModel)]="sourceMqProfile.qm">
    </div>
</div>

<div class="form-group row mb5">
    <label for="sourceMQProfileQueue" class="col-sm-3 col-form-label">Queue<span class="req-color">*</span></label>
    <div class="col-sm-6">
        <input type="text" class="form-control" id="sourceMQProfileQueue" name="sourceMQProfileQueue" placeholder="Queue" [(ngModel)]="sourceMqProfile.queue">
    </div>
</div>

<div class="form-group row" *ngIf="keyVaultProfiles">
    <label for="keyVaultSecretDDL" class="col-sm-3 col-form-label control-label">Key vault secret</label>
    <div class="col-sm-6">
        <select dropdown (change)="selectKeyVaultProfileChangeHandler($event)" id="keyVaultSecretDDL" name="keyVaultSecretDDL" class="form-select form-select-sm control-label" [(ngModel)]="sourceMqProfile.keyVaultProfileId">
            <option value="-1">--- Select secret ---</option>
            <option *ngFor="let item of keyVaultProfiles" [value]="item.id">
                {{item.name}}
            </option>
        </select>
    </div>
</div>
