import { Component, Input } from '@angular/core';
import { generate } from 'rxjs';
import { DropDownListModel } from 'src/app/models/dropdown-list-model';
import { KeyVaultProfile } from 'src/app/models/key-vault-profile';
import { SourceMqProfile } from 'src/app/models/source/source-mq-profile';

@Component({
  selector: 'source-mq-input',
  templateUrl: './source-mq.component.html',
})
export class SourceMqComponent {
    @Input() sourceMqProfile: SourceMqProfile;
    @Input() keyVaultProfiles: Array<KeyVaultProfile>;

    public selectedKeyVaultProfile: string = '-1';
    public selectedKeyVaultProfileValue: string;
    public selectedKeyVaultProfileText: string;

    selectKeyVaultProfileChangeHandler(event: any) {
      this.selectedKeyVaultProfileValue = event.target.value;
      this.selectedKeyVaultProfileText = event.target[event.target.selectedIndex].text;

      let ddl: DropDownListModel = new DropDownListModel();
      ddl.value = event.target.value;
      ddl.text = event.target[event.target.selectedIndex].text;
      //this.sbSecretUpdated.emit(ddl);
  }
}
