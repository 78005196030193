import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DropDownListModel } from 'src/app/models/dropdown-list-model';
import { KeyVaultProfile } from 'src/app/models/key-vault-profile';
import { Scheduler } from 'src/app/models/source/scheduler';
import { SourceSbProfile } from 'src/app/models/source/source-sb-profile';


@Component({
  selector: 'source-sb-input',
  templateUrl: './source-sb.component.html'
})
export class SourceSbComponent implements OnInit {
    @Input() sourceSbProfile: SourceSbProfile;
    @Input() sbKeyVaultProfiles: Array<KeyVaultProfile>;
    @Output() sbSecretUpdated = new EventEmitter<any>();
    @Output() sbProfileUpdated = new EventEmitter<any>();
    
    public systemName: string = '';
    public sbExistingSources: Array<DropDownListModel>;
    public scheduler: Scheduler;
    public useExistingKeyVaultValue: boolean = true;
    public keyVaultUpdateType: number = 1;
    
    ngOnInit(): void {}

    public selectedKeyVaultProfile: string = '-1';
    public selectedKeyVaultProfileValue: string;
    public selectedKeyVaultProfileText: string;

    // TODO If not needed => Remove!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    public onTextChange(event: any) {        
    }
   
    selectSBSourceChangeHandler(event: any) {
    }

    selectKeyVaultProfileChangeHandler(event: any) {
        this.selectedKeyVaultProfileValue = event.target.value;
        this.selectedKeyVaultProfileText = event.target[event.target.selectedIndex].text;

        let ddl: DropDownListModel = new DropDownListModel();
        ddl.value = event.target.value;
        ddl.text = event.target[event.target.selectedIndex].text;

        // When using a existing keyvault profile the payload need to be cleared as only the id is needed.
        this.sourceSbProfile.keyVaultProfile = null;

        // TODO Is this needed?
        this.sbSecretUpdated.emit(ddl);
    }

    public onSystemNameChange(event: any) {
        let keyVaultProfile = new KeyVaultProfile();
        keyVaultProfile.id = 0;
        keyVaultProfile.keyVaultName = null; // Will be set in API Controller
        this.sourceSbProfile.keyVaultProfileId = 0;
        keyVaultProfile.secretName = `al-goh-servicebus-auth-in-${this.systemName}-key`
        keyVaultProfile.name = `${this.systemName} SFTP Inbound Secret`
        this.sourceSbProfile.keyVaultProfile = keyVaultProfile;
    }

    public onUseManagedIdentityChange(event: any) {
        
    }

}
