<form [formGroup]="destinationForm" (submit)="save()">
    <div class="modal-header">
        <h4 class="modal-title">Add destination</h4>
    </div>
    
    <div class="modal-body">
        <div class="spinner-border loader alfalaval-blue-color spinner-center" *ngIf="showSpinner"></div>
        <div class="alert alert-danger" role="alert" *ngIf="errorModel">
            <b>Error in add destination form. Error Message: {{errorModel.errorMessage}}</b>
        </div>
    
        <div class="form-group row form-inline">
            <label for="protocolDDL" class="col-sm-3 col-form-label control-label mb10">Protocol</label>
            <div class="col-sm-9">
                <table>
                    <tr>
                        <td>
                            <select dropdown id="protocolDDL" formControlName="protocol" name="protocolDDL" class="form-select form-select-sm control-label" (change)="selectProtocolChangeHandler($event)">
                                <option value="">--- Select protocol ---</option>
                                <option value="2">File Share</option>
                                <option value="3">FTP</option>
                                <option value="9">Http</option>
                                <option value="7">MQ</option>
                                <option value="1">Service bus</option>
                                <option value="8">SFTP</option>
                            </select>
                        </td>
                    </tr>
                </table>                
            </div>
        </div>
    
        <!-- Scheduler -->
        <div *ngIf="destinationForm.value.protocol !== ''">
            <hr>
            <!-- Description -->
            <div class="form-group row mb5">
                <label for="destinationDescription" class="col-sm-3 col-form-label">Description<span class="req-color" >*</span></label>
                <div class="col-sm-9">
                    <input type="text" class="form-control" formControlName="description" id="destinationDescription" name="destinationDescription" placeholder="Description">
                    <div *ngIf="form.description.invalid && (form.description.dirty || form.description.touched)" class="alert alert-danger">
                        <div *ngIf="form.description.errors?.['required']">Description is required.</div>
                    </div>
                </div>
            </div>
            <!-- Is primary checkbox -->
            <div class="form-group row mb5">
                <label for="protocolDDL" class="col-sm-3 col-form-label control-label mb10">Endpoint type</label>
                <div class="col-sm-9">
                    <table>
                        <tr>
                            <td>
                                <select dropdown formControlName="type" id="endpointtype" name="endpointtype" class="form-select form-select-sm control-label">
                                    <option value="0">Primary</option>
                                    <option value="1">Secondary</option>                                    
                                </select>
                            </td>
                        </tr>
                    </table>                
                </div>
            </div>
        </div>
    
        <!-- Source -->
        <div>
            <http-profile *ngIf="destinationForm.value.protocol === '9'" formGroupName="destinationHttpprofileFormGroup" [isEdit]="false"></http-profile>
            <sb-profile *ngIf="destinationForm.value.protocol === '1'" formGroupName="destinationSbprofileFormGroup" [isEdit]="false"></sb-profile>
            <mq-profile *ngIf="destinationForm.value.protocol === '7'" formGroupName="destinationMqprofileFormGroup" [isEdit]="false"></mq-profile>
            <fileshare-profile *ngIf="destinationForm.value.protocol === '2'" formGroupName="destinationSharedFolderProfileFormGroup" [isEdit]="false"></fileshare-profile>
            <sftp-profile *ngIf="destinationForm.value.protocol === '8'" formGroupName="destinationSftpprofileFormGroup" [isEdit]="false"></sftp-profile>
            <ftp-profile *ngIf="destinationForm.value.protocol == '3'" formGroupName="destinationFtpprofileFormGroup" [isEdit]="false"></ftp-profile>
        </div>
    </div>
    <div class="modal-footer">
        <button type="submit" class="btn btn-outline-dark" [disabled]="!destinationForm.valid">Add</button>
        <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('cancel')">Cancel</button>
    </div>

    <!-- <div *ngIf="destinationForm">
        <pre style="margin-top:10px; border: 1px solid red; width: fit-content">
        {{ destinationForm.value | json }}
        </pre>
          </div>  -->
         
        <!-- Display each FormControl's validators -->
        <!-- <h3>Validators:</h3>
        <ul>
        <li *ngFor="let controlName of destinationForm.controls | keyvalue">
            <strong>{{ controlName.key }}</strong> Validators: 
            {{ getValidators(controlName.value) }}
        </li>
        </ul> -->

</form>
