import { Component, Input, OnInit } from '@angular/core';
import { KeyVaultProfile } from 'src/app/models/key-vault-profile';
import { KeyVaultService } from 'src/app/common-services/keyvault.service';
import { FtpProfileService } from './ftp-profile.service';
import { DestinationFtpprofile } from 'src/app/models/destination/destination-ftp-profile';
import { FormGroup, FormGroupDirective } from '@angular/forms';

@Component({
    selector: "ftp-profile",
    templateUrl: "./ftp-profile.component.html"
})
export class FtpProfileComponent implements OnInit {
    @Input() formGroupName: string;
    @Input() isEdit: boolean;

    public destinationFtpprofileForm: FormGroup;
    public keyVaultProfiles: Array<KeyVaultProfile>;
    public ftpprofiles: Array<DestinationFtpprofile>;

    constructor(private keyVaultService: KeyVaultService, private ftpProfileService: FtpProfileService, private rootFormGroup: FormGroupDirective) {}

    get form() { return this.destinationFtpprofileForm.controls; }

    ngOnInit(): void {
        this.destinationFtpprofileForm = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;

        this.ftpProfileService.getAllFtpProfiles().subscribe({
            next: (result) => {
                this.ftpprofiles = result;
            },
            // TODO Add better error hanlding to present the issue!
            error: (error) => {
                //this.showSpinner = false;
                console.error(error);
            }
        });

        this.keyVaultService.getKeyVaultProfiles().subscribe({
            next: (result) => {
                this.keyVaultProfiles = result;
            },
            // TODO Add better error hanlding to present the issue!
            error: (error) => {
                //this.showSpinner = false;
                console.error(error);
            }
        });
    }

    public handleDestOptionsChange(event: any) {
        this.ftpProfileService.setupRequiredValidation(this.destinationFtpprofileForm);        
    }

   
}