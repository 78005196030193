import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DropDownListModel } from 'src/app/models/dropdown-list-model';
import { SourceSbProfile } from 'src/app/models/source/source-sb-profile';
import { SourceMqProfile } from 'src/app/models/source/source-mq-profile';
import { SourceSharedFolderProfile } from 'src/app/models/source/source-shared-folder-profile';
import { KeyVaultProfile } from 'src/app/models/key-vault-profile';
import { Scheduler } from 'src/app/models/source/scheduler';
import { MDSourceInputService } from './md-source-input.service';
import { SourceSftpProfile } from 'src/app/models/source/source-sftp-profile';
import { ErrorParserService } from 'src/app/common-services/error-parser.service';
import { ErrorModel } from 'src/app/models/error-model';
import { SourceFtpProfile } from 'src/app/models/source/source-ftp-profile';

@Component({
  selector: 'add-partner',
  templateUrl: './add-source-model.component.html'
})
export class AddSourceModalComponent implements OnInit {
    @Input() scheduler: Scheduler;
    @Input() sourceProtocol: string = '';

    public errorModel: ErrorModel = null;
    public showSpinner: boolean = false;
    public requiredInputSet: boolean = false; // TODO Add validation of required input // TODO do for all!!!!!
    public schedulerEnabled: boolean = false;
    public sourceSbProfile: SourceSbProfile;
    public sourceMqProfile: SourceMqProfile;
    public sourceSharedFolderProfile: SourceSharedFolderProfile;
    public sourceSftpProfile: SourceSftpProfile
    public sourceFtpProfile: SourceFtpProfile
    public keyVaultProfiles: Array<KeyVaultProfile>;
    public ediType: string = '0';

    public ediTypes: Array<DropDownListModel> = [
      { value: '1', text: 'EDIFACT' },
      { value: '2', text: 'X12' }
    ];

    constructor(public activeModal: NgbActiveModal, public sourceInputService: MDSourceInputService, private errorParserService: ErrorParserService) {}

    ngOnInit(): void {
        
      this.sourceInputService.getKeyVaultProfiles().subscribe(
        {
            next: (result) => {
                this.keyVaultProfiles = result;
            },
            // TODO Add better error hanlding to present the issue!
            error: (error) => {
                this.showSpinner = false;
                this.errorModel = this.errorParserService.extractErrors(error);
                console.error(error);
            }
         }
      );      
    }

  public validateRequiredInputs() {
    let isSet: boolean = true;
    if(this.scheduler.name.length > 0) {
        isSet = false;
    }   

    this.requiredInputSet = isSet;     
  }

    public save() {
      // setup source for scheduler
      this.scheduler.source.sourceMQProfile = this.sourceMqProfile;
      this.scheduler.source.sourceSBProfile = this.sourceSbProfile;
      this.scheduler.source.sourceSharedFolderProfile = this.sourceSharedFolderProfile;
      this.scheduler.source.sourceSftpProfile = this.sourceSftpProfile;
      this.scheduler.source.sourceFtpProfile = this.sourceFtpProfile;
      this.scheduler.source.profileId = 0;
      this.scheduler.source.name = ' '; // Source name is not used (suggestion => Remove column in database)
      this.scheduler.source.protocolType = +this.sourceProtocol;

        this.sourceInputService.addScheduler(this.scheduler).subscribe(
            {
                next: (result) => {
                  this.activeModal.close('save');
                },
                error: (error) => {
                  this.errorModel = this.errorParserService.extractErrors(error);
                  console.error(error);
                }
             }
        );
    }

    public onSbSecretUpdated(ddl: DropDownListModel) {
        // this.sbSecretDdl = Object.assign({}, ddl);       
    }

    public onSftpSecretUpdated(ddl: DropDownListModel) {
      // this.sbSecretDdl = Object.assign({}, ddl);       
    }

    public onFtpSecretUpdated(ddl: DropDownListModel) {
      // this.sbSecretDdl = Object.assign({}, ddl);       
    }
    
  
    public selectEdiTypeChangeHandler(event: any) {
      let ediType = event.target.value
      
      if(ediType !== '0') {
        this.scheduler.source.ediType = +this.ediTypes.find(x => x.value === ediType).value;
      } else {
        this.scheduler.source.ediType = null;
      }      
    }

    selectProtocolChangeHandler(event: any) {
      switch(this.sourceProtocol) { 
        case '1': { // 'SB'
            //this.protocolHeaderText = 'Add Service Bus'
            this.sourceSbProfile = new SourceSbProfile(); // TODO Is this needed????????
            this.sourceSbProfile.id = 0;
            this.sourceSbProfile.useManagedIdentity = false;
            this.sourceSbProfile.keyVaultProfileId = -1;
            this.sourceSbProfile.name = ' '; // TODO How to set the name?
            this.sourceMqProfile = null;
            this.sourceSharedFolderProfile = null;
            this.sourceSftpProfile = null;
            this.sourceFtpProfile = null;
            break; 
        } 
        case '3': { // 'FTP'
          //this.protocolHeaderText = 'Add File Share'
          this.sourceFtpProfile = new SourceFtpProfile();
          this.sourceFtpProfile.id = 0;
          this.sourceFtpProfile.name = ' '; // TODO How to set the name?
          this.sourceFtpProfile.keyVaultProfileId = -1
          this.sourceSbProfile = null;
          this.sourceMqProfile = null;
          this.sourceSftpProfile = null;
          this.sourceSharedFolderProfile = null;
          break; 
        }
        case '7': { // 'MQ'
            //this.protocolHeaderText = 'Add MQ'
            this.sourceMqProfile = new SourceMqProfile();
            this.sourceMqProfile.id = 0;
            this.sourceMqProfile.name = ' '; // TODO How to set the name?
            this.sourceSharedFolderProfile = null;
            this.sourceSbProfile = null;
            this.sourceSftpProfile = null;
            this.sourceFtpProfile = null;
            break; 
        } 
        case '2': { // 'FILESHARE'
            //this.protocolHeaderText = 'Add File Share'
            this.sourceSharedFolderProfile = new SourceSharedFolderProfile();
            this.sourceSharedFolderProfile.id = 0;
            this.sourceSharedFolderProfile.name = ' '; // TODO How to set the name?
            this.sourceSbProfile = null;
            this.sourceMqProfile = null;
            this.sourceSftpProfile = null;
            this.sourceFtpProfile = null;
            break; 
        } 
        case '8': { // 'SFTP'
          //this.protocolHeaderText = 'Add File Share'
          this.sourceSftpProfile = new SourceSftpProfile();
          this.sourceSftpProfile.id = 0;
          this.sourceSftpProfile.name = ' '; // TODO How to set the name?
          this.sourceSftpProfile.keyVaultProfileId = -1
          this.sourceSbProfile = null;
          this.sourceMqProfile = null;
          this.sourceSharedFolderProfile = null;
          this.sourceFtpProfile = null;
          break; 
      } 

        
        default: { 
            break; 
        } 
    } 
  }   
}
