import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DropDownListModel } from 'src/app/models/dropdown-list-model';
import { SourceSbProfile } from 'src/app/models/source/source-sb-profile';
import { SourceMqProfile } from 'src/app/models/source/source-mq-profile';
import { SourceSharedFolderProfile } from 'src/app/models/source/source-shared-folder-profile';
import { KeyVaultProfile } from 'src/app/models/key-vault-profile';
import { Scheduler } from 'src/app/models/source/scheduler';
import { MDSourceInputService } from './md-source-input.service';
import { SourceSftpProfile } from 'src/app/models/source/source-sftp-profile';
import { SourceFtpProfile } from 'src/app/models/source/source-ftp-profile';

@Component({
  selector: 'add-partner',
  templateUrl: './edit-source-model.component.html'
})
export class EditSourceModalComponent implements OnInit {
    @Input() scheduler: Scheduler;
    @Input() sourceProtocol: string;

    public error: boolean = false;
    public errorMessage: string = '';
    public showSpinner: boolean = false;
    public requiredInputSet: boolean = false; // TODO Add validation of required input // TODO do for all!!!!!

    public schedulerEnabled: boolean = false;

    public sourceSbProfile: SourceSbProfile;
    public sourceMqProfile: SourceMqProfile;
    public sourceSharedFolderProfile: SourceSharedFolderProfile;
    public sourceSftpProfile: SourceSftpProfile
    public sourceFtpProfile: SourceFtpProfile
    public keyVaultProfiles: Array<KeyVaultProfile>;
    public ediType: string = '0';

    public ediTypes: Array<DropDownListModel> = [
        { value: '1', text: 'EDIFACT' },
        { value: '2', text: 'X12' }
      ];

    constructor(public activeModal: NgbActiveModal, public sourceInputService: MDSourceInputService) {}

    ngOnInit(): void {
        this.ediType = this.scheduler.source.ediType != null ? this.scheduler.source.ediType.toString(): '0';
        
        switch(this.sourceProtocol) { 
            case '1': { // 'SB'
                this.sourceSbProfile = this.scheduler.source.sourceSBProfile;
                break; 
            } 
            case '3': { // 'FTP'
                this.sourceFtpProfile = this.scheduler.source.sourceFtpProfile;
                break; 
            }
            case '7': { // 'MQ'
                this.sourceMqProfile = this.scheduler.source.sourceMQProfile;
                break; 
            } 
            case '2': { // 'FILESHARE'
                this.sourceSharedFolderProfile = this.scheduler.source.sourceSharedFolderProfile;
                break; 
            } 
            case '8': { // 'SFTP'
              this.sourceSftpProfile = this.scheduler.source.sourceSftpProfile;
              break; 
            }  
            default: { 
                break; 
            } 
        } 

        this.sourceInputService.getKeyVaultProfiles().subscribe(
        {
            next: (result) => {
                this.keyVaultProfiles = result;
            },
            // TODO Add better error hanlding to present the issue!
            error: (error) => {
                this.showSpinner = false;
                console.error(error);
            }
         });
    }

    public validateRequiredInputs() {
      let isSet: boolean = true;
      if(this.scheduler.name.length > 0) {
          isSet = false;
      }   

      this.requiredInputSet = isSet;     
  }

    public save() {
        // setup source for scheduler
        this.scheduler.source.sourceMQProfile = this.sourceMqProfile;
        this.scheduler.source.sourceSBProfile = this.sourceSbProfile;
        this.scheduler.source.sourceSharedFolderProfile = this.sourceSharedFolderProfile;
        this.scheduler.source.sourceSftpProfile = this.sourceSftpProfile;
        this.scheduler.source.sourceFtpProfile = this.sourceFtpProfile;
 
        this.sourceInputService.updateScheduler(this.scheduler).subscribe(
            {
                next: (result) => {
                     this.activeModal.close('save');
                },
                error: (error) => {
                    this.displayErrorMessage(error);
                }
             }
        );
    }

    selectChangeHandler(event: any) {
      }

    public onSbSecretUpdated(ddl: DropDownListModel) {
    }

    public onSftpSecretUpdated(ddl: DropDownListModel) {
    }

    public onFtpSecretUpdated(ddl: DropDownListModel) {
    }

    public selectEdiTypeChangeHandler(event: any) {
        let ediType = event.target.value
        
        if(ediType !== '0') {
          this.scheduler.source.ediType = +this.ediTypes.find(x => x.value === ediType).value;
        } else {
          this.scheduler.source.ediType = null;
        }      
      }

    selectProtocolChangeHandler(event: any) {
      switch(this.sourceProtocol) { 
        case '1': { // 'SB'
            this.sourceSbProfile = new SourceSbProfile(); // TODO Is this needed????????
            this.sourceSbProfile.id = 0;
            this.sourceSbProfile.keyVaultProfileId = -1;
            this.sourceSbProfile.useManagedIdentity = false;
            this.sourceSbProfile.name = ' '; // TODO How to set the name?
            this.sourceMqProfile = null;
            this.sourceSharedFolderProfile = null;
            this.sourceSftpProfile = null;
            this.sourceFtpProfile = null;
            break; 
        } 
        case '3': { // 'FTP'
            this.sourceFtpProfile = new SourceFtpProfile();
            this.sourceFtpProfile.id = 0;
            this.sourceFtpProfile.name = ' '; // TODO How to set the name?
            this.sourceFtpProfile.keyVaultProfileId = -1
            this.sourceSbProfile = null;
            this.sourceMqProfile = null;
            this.sourceSftpProfile = null;
            this.sourceSharedFolderProfile = null;
            break; 
          } 
        case '7': { // 'MQ'
            this.sourceMqProfile = new SourceMqProfile();
            this.sourceMqProfile.id = 0;
            this.sourceMqProfile.name = ' '; // TODO How to set the name?
            this.sourceSharedFolderProfile = null;
            this.sourceSbProfile = null;
            this.sourceSftpProfile = null;
            this.sourceFtpProfile = null;
            break; 
        } 
        case '2': { // 'FILESHARE'
            this.sourceSharedFolderProfile = new SourceSharedFolderProfile();
            this.sourceSharedFolderProfile.id = 0;
            this.sourceSharedFolderProfile.name = ' '; // TODO How to set the name?
            this.sourceSbProfile = null;
            this.sourceMqProfile = null;
            this.sourceSftpProfile = null;
            this.sourceFtpProfile = null;
            break; 
        } 
        case '8': { // 'SFTP'
          this.sourceSftpProfile = new SourceSftpProfile();
          this.sourceSftpProfile.id = 0;
          this.sourceSftpProfile.name = ' '; // TODO How to set the name?
          this.sourceSftpProfile.keyVaultProfileId = -1
          this.sourceSbProfile = null;
          this.sourceMqProfile = null;
          this.sourceSharedFolderProfile = null;
          this.sourceFtpProfile = null;
          break; 
        } 

        
            default: { 
                break; 
            } 
        }       
    }

    private displayErrorMessage(error: any) {
        let formattedError = '';
        if(error?.error && error?.error.errors) {
          formattedError = error?.error.errors;
        }

        if(error?.error && error?.error.error == undefined) {
        if(error.error.includes('at al.goh.admin.tool.api')) {
            formattedError = error.error.substring(0, error.error.indexOf('at al.goh.admin.tool.api'));
        } else {
            formattedError = error.error;
        }
        } else {
        if(error?.error?.error) {
            formattedError = error?.error.error;
        } else {
            formattedError = 'Unknown error';
        }
        }

        this.errorMessage = `Error when decoding message. Error Message ${formattedError}`;
        this.error = true;
        console.error(error);
    }
}
