<form [formGroup]="destinationSbprofileForm">
    <hr>
    <!-- Selection -->
   <div class="form-group row mb5" *ngIf="!isEdit">
       <div class="col-sm-3">        
       </div>
       <div class="col-sm-9">
           <div class="form-check">
               <input class="form-check-input" type="radio" formControlName="useExistingDestination" name="useExistingDestination" (change)="handleDestOptionsChange($event)" [value]="true" id="useExistingDestination">
               <label class="form-check-label" for="useExistingDestination">
               Use existing destination
               </label>
           </div>
           <div class="form-check">
               <input class="form-check-input" type="radio" formControlName="useExistingDestination" name="useExistingDestination" (change)="handleDestOptionsChange($event)" [value]="false" id="useExistingDestination">
               <label class="form-check-label" for="useExistingDestination">
                   Add new destination
               </label>
           </div>
       </div>
   </div>
   
    <!-- Use existing physical destination -->
    <div class="form-group row" *ngIf="destinationSbprofileForm.value.useExistingDestination === true">
       <label for="confDestinationDDL" class="col-sm-3 col-form-label control-label">Destinations <span class="req-color">*</span></label>
       <div class="col-sm-9">
           <select dropdown formControlName="id" id="confDestinationDDL" name="confDestinationDDL" class="form-select form-select-sm control-label">
           <option value="">--- Select destination ---</option>
           <option *ngFor="let item of sbProfiles" [value]="item.id">
               {{item.name}} (namespace: {{item.namespace}}, queue: {{item.queue}})
           </option>
           </select>
       </div>
   </div>
   
   <!-- New physical destination -->
   <div *ngIf="destinationSbprofileForm.value.useExistingDestination === false">
        <!-- Namespace -->
        <div class="form-group row mb5">
            <label for="destinationNamespace" class="col-sm-3 col-form-label">Namespace<span class="req-color">*</span></label>
            <div class="col-sm-9">
                <input type="text" class="form-control" formControlName="namespace" id="destinationNamespace" name="destinationNamespace" placeholder="Namespace">
            </div>
        </div>
       <!-- Queue -->
        <div class="form-group row mb5">
            <label for="destinationQueue" class="col-sm-3 col-form-label">Queue<span class="req-color">*</span></label>
            <div class="col-sm-9">
                <input type="text" class="form-control" formControlName="queue" id="destinationQueue" name="destinationQueue" placeholder="Queue">
            </div>
        </div>
        <!-- Use session checkbox -->
        <div class="form-group row mb5">
            <div class="col-sm-3 col-form-label"></div>
            <div class="col-sm-9">
                <div class="form-check">
                    <input class="form-check-input" formControlName="useSession" type="checkbox" id="checkboxUseSession" value="" aria-label="...">
                    <label class="form-check-label" for="checkboxUseSession">
                        Use session
                    </label>
                </div>
            </div>
        </div>
   
       <!-- Selection -->
       <div class="row">
           <div class="col-sm-3"></div>
           <div class="col-sm-9">
               <div class="form-check">
                   <input class="form-check-input" formControlName="useExistingKeyVaultValue" type="radio" name="useExistingKeyVaultValue" [value]="true" id="useExistingKeyVaultValue">
                   <label class="form-check-label" for="useExistingKeyVaultValue">
                       Use existing key vault secret
                   </label>
               </div>
               <div class="form-check">
                   <input class="form-check-input" formControlName="useExistingKeyVaultValue" type="radio" name="useExistingKeyVaultValue" [value]="false" id="useExistingKeyVaultValue">
                   <label class="form-check-label" for="useExistingKeyVaultValue">
                       Add key vault secret
                   </label>
               </div>
           </div>
       </div>  
        <!-- Shared access key name -->
       <div class="form-group row mb5">
           <label for="sharedAccessKeyName" class="col-sm-3 col-form-label">Shared access key name<span class="req-color">*</span></label>
           <div class="col-sm-9">
               <input type="text" class="form-control" formControlName="sharedAccessKeyName" id="sharedAccessKeyName" name="sharedAccessKeyName" placeholder="Shared access key name">
           </div>
       </div>
       <!-- System name -->
       <div class="form-group row mb5" *ngIf="destinationSbprofileForm.value.useExistingKeyVaultValue === false">
           <label for="systemName" class="col-sm-3 col-form-label">System name <i class="fa fa-info-circle alfalaval-blue-color" aria-hidden="true" placement="top" ngbTooltip="The secret that will store the ServiceBus-key has the form of al-goh-servicebus-auth-out-{system name}-key. The value you provide in the System name input will be used to construct the secret name."></i></label>
           <div class="col-sm-9">
               <input type="text" class="form-control" formControlName="systemName" id="systemName" name="systemName" placeholder="System name">
           </div>
       </div>
       <!-- Access key -->
       <div class="form-group row mb5" *ngIf="destinationSbprofileForm.value.useExistingKeyVaultValue === false">
           <label for="connectionString" class="col-sm-3 col-form-label">Access key</label>
           <div class="col-sm-9">
               <!-- Here connectionString is hijacked just to pass key to server side-->
               <input type="password" formControlName="connectionString" class="form-control" id="connectionString" name="connectionString" placeholder="Access key">
           </div>
       </div>
       <!--  -->
       <div class="form-group row mb5" *ngIf="keyVaultProfiles && destinationSbprofileForm.value.useExistingKeyVaultValue === true">
           <label for="keyvaultsecretDDL" class="col-sm-3 col-form-label control-label">Key vault secret</label>
           <div class="col-sm-9">
               <select dropdown formControlName="keyVaultProfileId" id="keyvaultsecretDDL" name="keyvaultsecretDDL" class="form-select form-select-sm control-label">
                   <option value="-1">--- Select secret ---</option>
                   <option *ngFor="let item of keyVaultProfiles" [value]="item.id">
                       {{item.name}}
                   </option>
               </select>
           </div>
       </div>
   </div>
</form>