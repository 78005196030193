<div class="modal-header">
  <h4 class="modal-title" *ngIf="tracking == null">View tracking details</h4>
  <h4 class="modal-title" *ngIf="tracking != null && tracking[0]">View tracking details (partner: {{tracking[0].partnerId}})</h4>
</div>

<div class="modal-body">
  <div>
    <div class="alert alert-danger" role="alert" *ngIf="errorModel">
      <b>Error when viewing tracking data. {{errorModel.errorMessage}}</b>
    </div>
    <fieldset class="border p-2" style="margin-top: 10px;">
      <legend class="float-none w-auto legend-style"><span style="margin-left: 5px; margin-right: 5px;">Details</span></legend>
        <div class="row mb5">
          <div class="col-sm-2">
              <label for="receivedTimestampLabel"><b>Recieved Timestamp (UTC):</b></label>
          </div>
          <div class="col-sm-3">
              <label for="receivedTimestampValue" *ngIf="trackingView != null">{{trackingView.timestamp | date: 'yyyy-MM-dd HH:mm:ss'}}</label>
          </div>
          <div class="col-sm-2">
              <label for="updatedTimestampLabel"><b>Updated Timestamp(UTC):</b></label>
          </div>
          <div class="col-sm-3">
              <label for="updatedTimestampValue" *ngIf="tracking != null && tracking[0]">{{tracking[0].timestamp | date: 'yyyy-MM-dd HH:mm:ss'}}</label>
          </div>
        </div>
        <div class="row mb5">
          <div class="col-sm-2">
              <label for="flowIdLabel"><b>Flow Id:</b></label>
          </div>
          <div class="col-sm-3">
              <label for="flowIdValue" *ngIf="trackingEventDetails != null">{{tracking[0].flowID}}</label>
          </div>
          <div class="col-sm-2">
              <label for="correlationIdLabel"><b>Correlation Id:</b></label>
          </div>
          <div class="col-sm-3">
              <label for="correlationIdValue" *ngIf="tracking != null && tracking[0]">{{tracking[0].correlationId}}</label>
          </div>
        </div>
        <div class="row mb5">
          <div class="col-sm-2">
              <label for="messageTypeLabel"><b>Message type Id:</b></label>
          </div>
          <div class="col-sm-3">
              <label for="messageTypeValue" *ngIf="tracking != null && tracking[0]">{{tracking[0].messageType}}</label>
          </div>
          <div class="col-sm-2">
              <label for="messageTypeNameLabel"><b>Message type description:</b></label>
          </div>
          <div class="col-sm-3">
              <label for="messageTypeNameValue" *ngIf="tracking != null && tracking[0]">{{tracking[0].messageTypeName}}</label>
          </div>
        </div>
        <div class="row mb5">
          <div class="col-sm-2">
              <label for="senderIDLabel"><b>Sender Id:</b></label>
          </div>
          <div class="col-sm-3">
              <label for="senderIDValue" *ngIf="tracking != null && tracking[0]">{{tracking[0].senderID}}</label>
          </div>
          <div class="col-sm-2">
              <label for="senderNameLabel"><b>Sender name:</b></label>
          </div>
          <div class="col-sm-3">
              <label for="senderNameValue" *ngIf="tracking != null && tracking[0]">{{tracking[0].senderName}}</label>
          </div>
        </div>

        <div class="row mb5">
          <div class="col-sm-2">
              <label for="receiverIDLabel"><b>Receiver Id:</b></label>
          </div>
          <div class="col-sm-3">
              <label for="receiverIDValue" *ngIf="tracking != null && tracking[0]">{{tracking[0].receiverID}}</label>
          </div>
          <div class="col-sm-2">
              <label for="receiverNameLabel"><b>Receiver name:</b></label>
          </div>
          <div class="col-sm-3">
              <label for="receiverNameValue" *ngIf="tracking != null && tracking[0]">{{tracking[0].receiveName}}</label>
          </div>
        </div>

        <div class="row mb5">
          <div class="col-sm-2">
              <label for="referenceIDLabel"><b>Reference Id:</b></label>
          </div>
          <div class="col-sm-3">
              <label for="referenceIDValue" *ngIf="tracking != null && tracking[0]">{{tracking[0].referenceID}}</label>
          </div>
          <div class="col-sm-2">
              <label for="primaryMessageIDLabel"><b>Primary message Id:</b></label>
          </div>
          <div class="col-sm-3">
              <label for="primaryMessageIDValue" *ngIf="tracking != null && tracking[0]">{{tracking[0].primaryMessageID}}</label>
          </div>
        </div>

        <div class="row mb5">
          <div class="col-sm-2">
              <label for="areaPlantIdLabel"><b>Area Plant Id:</b></label>
          </div>
          <div class="col-sm-3">
              <label for="areaPlantIdValue" *ngIf="tracking != null && tracking[0]">{{tracking[0].areaPlantId}}</label>
          </div>
          <div class="col-sm-2">
              <label for="customLookupIdLable"><b>Custom Lookup Id:</b></label>
          </div>
          <div class="col-sm-3">
              <label for="customLookupIdValue" *ngIf="tracking != null && tracking[0]">{{tracking[0].customLookupId}}</label>
          </div>
        </div>

        <div class="row mb5">
          <div class="col-sm-2">
              <label for="countryCodeLabel"><b>Country Code:</b></label>
          </div>
          <div class="col-sm-3">
              <label for="countryCodeValue" *ngIf="tracking != null && tracking[0]">{{tracking[0].countryCode}}</label>
          </div>
        </div>
        
        
        <fieldset class="border p-2 mb10 mt10" *ngIf="hasAS2Details">
          <legend class="float-none w-auto legend-style"><span style="margin-left: 5px; margin-right: 5px;">AS2 Details</span></legend>
          <div class="row mb5">
            <div class="col-sm-2">
                <label for="countryCodeLabel"><b>AS2 MessageId:</b></label>
            </div>
            <div class="col-sm-3">
                <label for="countryCodeValue" *ngIf="trackingEventAS2Details != null && trackingEventAS2Details[0]">{{trackingEventAS2Details[0].aS2MessageId}}</label>
            </div>
            <div class="col-sm-2">
              <label for="countryCodeLabel"><b>AS2 Agreement Name:</b></label>
            </div>
            <div class="col-sm-3">
                <label for="countryCodeValue" *ngIf="trackingEventAS2Details != null && trackingEventAS2Details[0]">{{trackingEventAS2Details[0].aS2Agreement}}</label>
            </div>
          </div>
          <div class="row mb5">
            <div class="col-sm-2">
                <label for="countryCodeLabel"><b>AS2 Sender:</b></label>
            </div>
            <div class="col-sm-3">
                <label for="countryCodeValue" *ngIf="trackingEventAS2Details != null && trackingEventAS2Details[0]">{{trackingEventAS2Details[0].aS2Sender}}</label>
            </div>
            <div class="col-sm-2">
              <label for="countryCodeLabel"><b>AS2 SenderId:</b></label>
            </div>
            <div class="col-sm-3">
                <label for="countryCodeValue" *ngIf="trackingEventAS2Details != null && trackingEventAS2Details[0]">{{trackingEventAS2Details[0].aS2SenderId}}</label>
            </div>
          </div>
          <div class="row mb5">
            <div class="col-sm-2">
                <label for="countryCodeLabel"><b>AS2 Receiver:</b></label>
            </div>
            <div class="col-sm-3">
                <label for="countryCodeValue" *ngIf="trackingEventAS2Details != null && trackingEventAS2Details[0]">{{trackingEventAS2Details[0].aS2Receiver}}</label>
            </div>
            <div class="col-sm-2">
              <label for="countryCodeLabel"><b>AS2 ReceiverId:</b></label>
            </div>
            <div class="col-sm-3">
                <label for="countryCodeValue" *ngIf="trackingEventAS2Details != null && trackingEventAS2Details[0]">{{trackingEventAS2Details[0].aS2ReceiverId}}</label>
            </div>
          </div>
          <div class="row mb5">
            <div class="col-sm-2">
                <label for="countryCodeLabel"><b>MDN Timestamp:</b></label>
            </div>
            <div class="col-sm-3">
                <label for="countryCodeValue" *ngIf="trackingEventAS2Details != null && trackingEventAS2Details[0]">{{trackingEventAS2Details[0].aS2MdnTimestamp}}</label>
            </div>
            <div class="col-sm-2">
              <label for="countryCodeLabel"><b>MDN Type:</b></label>
            </div>
            <div class="col-sm-3">
                <label for="countryCodeValue" *ngIf="trackingEventAS2Details != null && trackingEventAS2Details[0]">{{trackingEventAS2Details[0].aS2MdnType}}</label>
            </div>
          </div>
          <div class="row mb5">
            <div class="col-sm-2">
                <label for="countryCodeLabel"><b>MDN MessageId:</b></label>
            </div>
            <div class="col-sm-3">
                <label for="countryCodeValue" *ngIf="trackingEventAS2Details != null && trackingEventAS2Details[0]">{{trackingEventAS2Details[0].aS2MdnMessageId}}</label>
            </div>
            <div class="col-sm-2">
              <label for="countryCodeLabel"><b>MDN Error:</b></label>
            </div>
            <div class="col-sm-3">
                <label for="countryCodeValue" *ngIf="trackingEventAS2Details != null && trackingEventAS2Details[0]">{{trackingEventAS2Details[0].aS2MdnError}}</label>
            </div>
          </div>
          <div class="row mb5">
            <div class="col-sm-2">
              <label for="countryCodeLabel"><b>MDN FlowId:</b></label>
          </div>
          <div class="col-sm-3">
              <label for="countryCodeValue" *ngIf="trackingEventAS2Details != null && trackingEventAS2Details[0]">{{trackingEventAS2Details[0].aS2MdnFlowId}}</label>
          </div>
            <div class="col-sm-2">
                <label for="countryCodeLabel"><b>MDN Url:</b></label>
            </div>
            <div class="col-sm-3">
                <label for="countryCodeValue" *ngIf="trackingEventAS2Details != null && trackingEventAS2Details[0]">{{trackingEventAS2Details[0].aS2MdnUri}}</label>
            </div>
          </div>
        </fieldset>

        <!-- Look at the length of trackingEventDeliveries to display instead of hasSentRows -->
        <fieldset class="border p-2 mb10 mt10" *ngIf="hasSentRows">
          <legend class="float-none w-auto legend-style"><span style="margin-left: 5px; margin-right: 5px;">Send information</span></legend>
          <div>
            <!-- TODO Add this and remove the above when new trackingEventDeliveries is implemented in integration code -->
            <table class='table table-striped table-bordered' aria-labelledby="tableLabel" *ngIf="trackingEventDetails.trackingEventDeliveries.length > 0">
              <thead>
                <tr>
                    <th>
                      <div class="row" style="margin-left: 1px; margin-right: 5px;">Timestamp (utc)</div>
                    </th>
                    <th>
                      <div class="row" style="margin-left: 1px; margin-right: 5px;">Endpoint type</div>
                    </th>
                    <th>
                      <div class="row" style="margin-left: 1px; margin-right: 5px;">Sent</div>
                    </th>
                    <th>
                      <div class="row" style="margin-left: 1px; margin-right: 5px;">Message</div>
                    </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let sentRow of trackingEventDetails.trackingEventDeliveries" [ngClass]="sentRow.success === false ? 'alert alert-danger' : '' ">
                    <td>{{ sentRow.timeStamp | date: 'yyyy-MM-dd HH:mm:ss' }}</td>
                    <td>{{ sentRow.endpointType }}</td>
                    <td>{{ utilService.getYesNo(sentRow.success) }}</td>
                    <td>{{ trackerService.getSentMessage(sentRow) }}</td>
                </tr>
            </tbody>
            </table>
          </div>
        </fieldset>

        <fieldset class="border p-2 mb10 mt10" *ngIf="isCancel">
          <legend class="float-none w-auto legend-style"><span style="margin-left: 5px; margin-right: 5px;">Cancel messages</span></legend>
            <simple-grid [columns]="cancelRowsColumns" [data]="cancelRowsData" [tableClass]="'table table-striped table-bordered'" [rowClass]="'alert alert-warning'"></simple-grid>
        </fieldset>

        <fieldset class="border p-2 mb10 mt10" *ngIf="isError">
          <legend class="float-none w-auto legend-style"><span style="margin-left: 5px; margin-right: 5px;">Error messages</span></legend>
          <simple-grid [columns]="errorRowsColumns" [data]="errorRowsData" [tableClass]="'table table-striped table-bordered'" [rowClass]="'alert alert-danger'"></simple-grid>
        </fieldset>

        <div *ngIf="tracking" class="mt10">
          <ul ngbNav #nav="ngbNav" [(activeId)]="tabModel.activeTab" class="nav-tabs">
            <li [ngbNavItem]="1" *ngIf="showAS2Encoded">
              <a ngbNavLink (click)="onclickGoToTab(1)">AS2 Encoded</a>
              <ng-template ngbNavContent>
                <div class="ml5">
                  <div class="row">
                    <div class="col-12">
                        <div style="margin-bottom: 10px;">
                          <textarea class="form-control" id="payloadAS2Encoded" rows="25" [(ngModel)]="payloadAS2Encoded"></textarea>
                        </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </li>
            <li [ngbNavItem]="2" *ngIf="showAS2Decoded">
              <a ngbNavLink (click)="onclickGoToTab(2)">{{editTypeName}}</a>
              <ng-template ngbNavContent>
                <div class="ml5">
                  <div class="row">
                    <div class="col-12">
                        <div style="margin-bottom: 10px;">
                          <textarea class="form-control" id="payloadAS2Decoded" rows="25" [(ngModel)]="payloadAS2Decoded"></textarea>
                        </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </li>
            <li [ngbNavItem]="3" *ngIf="showInfileTab">
              <a ngbNavLink (click)="onclickGoToTab(3)">In</a>
              <ng-template ngbNavContent>
                <div class="ml5">
                  <div class="row">
                    <div class="col-12">
                        <div style="margin-bottom: 10px;">
                          <textarea class="form-control" id="payloadIn" rows="25" [(ngModel)]="payloadIn"></textarea>
                        </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </li>
            <li [ngbNavItem]="4" *ngIf="showCanonicalfileTab">
              <a ngbNavLink (click)="onclickGoToTab(4)">Canonical</a>
              <ng-template ngbNavContent>
                  <div class="ml5">
                    <div class="row">
                      <div class="col-12">
                          <div style="margin-bottom: 10px;">
                            <textarea class="form-control" id="payloadCanonical" rows="25" [(ngModel)]="payloadCanonical"></textarea>
                          </div>
                      </div>
                    </div>
                  </div>
              </ng-template>
            </li>
            <li [ngbNavItem]="5" *ngIf="showEnrichedCanonicalfileTab">
              <a ngbNavLink (click)="onclickGoToTab(5)">Enriched canonical</a>
              <ng-template ngbNavContent>
                  <div class="ml5">
                    <div class="row">
                      <div class="col-12">
                          <div style="margin-bottom: 10px;">
                            <textarea class="form-control" id="payloadCanonical" rows="25" [(ngModel)]="payloadEnrichedCanonical"></textarea>
                          </div>
                      </div>
                    </div>
                  </div>
              </ng-template>
            </li>
            <li [ngbNavItem]="6" *ngIf="showEdiPreEncoded">
              <a ngbNavLink (click)="onclickGoToTab(6)">Pre-encoded</a>
              <ng-template ngbNavContent>
                  <div class="ml5">
                    <div class="row">
                      <div class="col-12">
                          <div style="margin-bottom: 10px;">
                            <textarea class="form-control" id="payloadPreEncoded" rows="25" [(ngModel)]="payloadPreEncoded"></textarea>
                          </div>
                      </div>
                    </div>
                  </div>
              </ng-template>
            </li>
            <li [ngbNavItem]="7" *ngIf="showEdiEncoded">
              <a ngbNavLink (click)="onclickGoToTab(7)">Encoded</a>
              <ng-template ngbNavContent>
                  <div class="ml5">
                    <div class="row">
                      <div class="col-12">
                          <div style="margin-bottom: 10px;">
                            <textarea class="form-control" id="payloadEncoded" rows="25" [(ngModel)]="payloadEncoded"></textarea>
                          </div>
                      </div>
                    </div>
                  </div>
              </ng-template>
            </li>
            <li [ngbNavItem]="8" *ngIf="showOutFileTab">
              <a ngbNavLink (click)="onclickGoToTab(8)">Out</a>
              <ng-template ngbNavContent>
                <div class="ml5">
                  <div class="row">
                    <div class="col-12">
                        <div style="margin-bottom: 10px;">
                          <textarea class="form-control" id="payloadOut" rows="25" [(ngModel)]="payloadOut"></textarea>
                        </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </li>
            <li [ngbNavItem]="9" *ngIf="showErrorTab">
              <a ngbNavLink (click)="onclickGoToTab(9)">Error</a>
              <ng-template ngbNavContent>
                <div class="ml5">
                  <div class="row">
                    <div class="col-12">
                        <div style="margin-bottom: 10px;">
                          <textarea class="form-control" id="payloadError" rows="25" [(ngModel)]="payloadError"></textarea>
                        </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </li>
            <li [ngbNavItem]="10" *ngIf="showAS2InboundMDN">
              <a ngbNavLink (click)="onclickGoToTab(10)">Received MDN</a>
              <ng-template ngbNavContent>
                <div class="ml5">
                  <div class="row">
                    <div class="col-12">
                        <div style="margin-bottom: 10px;">
                          <textarea class="form-control" id="payloadAS2InboundMDN" rows="25" [(ngModel)]="payloadAS2InboundMDN"></textarea>
                        </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </li>
            <li [ngbNavItem]="11" *ngIf="showAS2OutboundMDN">
              <a ngbNavLink (click)="onclickGoToTab(11)">Sent MDN</a>
              <ng-template ngbNavContent>
                <div class="ml5">
                  <div class="row">
                    <div class="col-12">
                        <div style="margin-bottom: 10px;">
                          <textarea class="form-control" id="payloadAS2OutboundMDN" rows="25" [(ngModel)]="payloadAS2OutboundMDN"></textarea>
                        </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </li>
            <li [ngbNavItem]="12" *ngIf="showMdnDecoded">
              <a ngbNavLink (click)="onclickGoToTab(12)">MDN Decoded</a>
              <ng-template ngbNavContent>
                <div class="ml5">
                  <div class="row">
                    <div class="col-12">
                        <div style="margin-bottom: 10px;">
                          <textarea class="form-control" id="payloadMdnDecoded" rows="25" [(ngModel)]="payloadMdnDecoded"></textarea>
                        </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </li>
          </ul>
          <div [ngbNavOutlet]="nav" class="mt-2"></div>
        </div>
    </fieldset>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
</div>
