import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfigurationWizardComponent } from './configuration-wizard.component';
import { ConfigurationWizardService } from './configuration-wizard.service';
import { ConfigurationTabService } from './configuration-tab.service';
import { As2InboundInputComponent } from './child-components/as2/as2-inbound-input.component';
import { As2OutboundInputComponent } from './child-components/as2/as2-outbound-input.component';
import { DestinationInputComponent } from './child-components/destination/destination-input.component';
import { EnrichmentComponent } from './child-components/enrichment/enrichment.component';
import { InboundMappingInputComponent } from './child-components/inbound-mapping/inbound-mapping-input.component';
import { MessagetypeInputComponent } from './child-components/message-type/messagetype-input.component';
import { OutboundMappingInputComponent } from './child-components/outbound-mapping/outbound-mapping-input.component';
import { ReviewwInputService } from './child-components/review/review-input.service';
import { SourceInputService } from '../../master-data/partners/child-components/source/source-input.service';
import { PartnerInputComponent } from './child-components/partner/partner-input.component';
import { PostFormattingInputComponent } from './child-components/post-formatting/post-formatting-input.component';
import { PreFormattingInputComponent } from './child-components/pre-formatting/pre-formatting-input.component';
import { ResponseResolverInputComponent } from './child-components/response-resolver/response-resolver-input.component';
import { ReviewInputComponent } from './child-components/review/review-input.component';
import { EntityMapInputComponent } from './child-components/entity-map/entitymap-input.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DestinationInputService } from './child-components/destination/destination-input.service';
import { UtilService } from 'src/app/common-services/util.service';
import { ResolverInfoComponent } from './child-components/resolver/resolver-info.component';
import { OutboundPartnerInputComponent } from './child-components/partner/outbound-partner-input.component';
import { ReviewOutboundPartnerComponent } from './child-components/review/child-components/review-outbound-partner.component';
import { ReviewInboundPartnerComponent } from './child-components/review/child-components/review-inbound-partner.component';
import { ReviewMessageTypeComponent } from './child-components/review/child-components/review-message-type.component';
import { ReviewEntitymapComponent } from './child-components/review/child-components/review-entitymap.component';
import { ReviewSourceComponent } from './child-components/review/child-components/review-source.component';
import { ReviewPreFormattingComponent } from './child-components/review/child-components/review-pre-formatting.component';
import { ReviewResponseResolverComponent } from './child-components/review/child-components/review-response-resolver.component';
import { ReviewResolverComponent } from './child-components/review/child-components/review-resolver.component';
import { ReviewParserComponent } from './child-components/review/child-components/review-parser.component';
import { ReviewEnrichmentComponent } from './child-components/review/child-components/review-enrichment.component';
import { ReviewMappingComponent } from './child-components/review/child-components/review-mapping.component';
import { ReviewPostFormattingComponent } from './child-components/review/child-components/review-post-formatting.component';
import { ReviewDestinationsComponent } from './child-components/review/child-components/review-destinations.component';
import { ReviewAS2ConfoutComponent } from './child-components/review/child-components/review-as2-confout.component';
import { ReviewEdiLookupComponent } from './child-components/review/child-components/review-edi-lookup.component';
import { ReviewX12LookupComponent } from './child-components/review/child-components/review-x12-lookup.component';
import { ParserInfoComponent } from './child-components/parser/parser-info.component';
import { OutboundMessagetypeInputComponent } from './child-components/message-type/outbound-messagetype-input.component';
import { ReviewOutboundMessageTypeComponent } from './child-components/review/child-components/review-outbound-message-type.component';
import { SourceViewComponent } from './child-components/source/source.component';
import { SourceService } from './child-components/source/source.service';
import { CommonComponentsModule } from 'src/app/common-components/common-components.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { PreStepInfoComponent } from './child-components/pre-step/pre-step-info.component';
import { SourceViewTableRowModule } from 'src/app/common-components/source-vew/source-view-table-data.module';
import { ErrorDisplayModule } from 'src/app/common-components/error-display/error-display.module';
import { ReviewAS2ConfInComponent } from './child-components/review/child-components/review-as2-confin.component';

@NgModule({
  imports:      [
    CommonModule,
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgbModule,
    CommonComponentsModule,
    SourceViewTableRowModule,
    ErrorDisplayModule
  ],
  providers:    [
        ConfigurationWizardService,
        ConfigurationTabService,
        ReviewwInputService,
        SourceInputService,
        SourceService,
        DestinationInputService,
        UtilService,
        As2OutboundInputComponent
    ],
  declarations: [
    ConfigurationWizardComponent,
    As2InboundInputComponent,
    As2OutboundInputComponent,
    DestinationInputComponent,
    EnrichmentComponent,
    EntityMapInputComponent,
    ResolverInfoComponent,
    InboundMappingInputComponent,
    MessagetypeInputComponent,
    OutboundMappingInputComponent,
    ParserInfoComponent,
    PartnerInputComponent,
    OutboundPartnerInputComponent,
    PostFormattingInputComponent,
    PreFormattingInputComponent,
    ResponseResolverInputComponent,
    ReviewInputComponent,
    SourceViewComponent,
    // Review child components. TODO Create module for Review child and make it work
    ReviewOutboundPartnerComponent,
    ReviewInboundPartnerComponent,
    ReviewMessageTypeComponent,
    ReviewEntitymapComponent,
    ReviewSourceComponent,
    ReviewPreFormattingComponent,
    ReviewResponseResolverComponent,
    ReviewResolverComponent,
    ReviewParserComponent,
    ReviewEnrichmentComponent,
    ReviewMappingComponent,
    ReviewPostFormattingComponent,
    ReviewDestinationsComponent,
    ReviewAS2ConfoutComponent,
    ReviewEdiLookupComponent,
    ReviewX12LookupComponent,
    ReviewAS2ConfInComponent,
    OutboundMessagetypeInputComponent,
    ReviewOutboundMessageTypeComponent,
    PreStepInfoComponent
   ]
})
export class ConfigurationWizardModule { }
