<!-- Host -->
<div class="form-group row mb5">
    <label for="sourceFtpProfileHost" class="col-sm-3 col-form-label">Host<span class="req-color">*</span></label>
    <div class="col-sm-6">
        <input type="text" class="form-control" id="sourceFtpProfileHost" name="sourceFtpProfileHost" placeholder="Host" [(ngModel)]="sourceFtpProfile.host" (change)="onTextChange($event)">
    </div>
</div>
<!-- Port -->
<div class="form-group row mb5">
    <label for="sourceFtpProfilePort" class="col-sm-3 col-form-label">Port<span class="req-color">*</span></label>
    <div class="col-sm-6">
        <input type="text" class="form-control" id="sourceFtpProfilePort" name="sourceFtpProfilePort" placeholder="Port" [(ngModel)]="sourceFtpProfile.port" (change)="onTextChange($event)">
    </div>
</div>
<!-- Path -->
<div class="form-group row mb5">
    <label for="sourceFtpProfilePath" class="col-sm-3 col-form-label">Path<span class="req-color">*</span></label>
    <div class="col-sm-6">
        <input type="text" class="form-control" id="sourceFtpProfilePath" name="sourceFtpProfilePath" placeholder="Path" [(ngModel)]="sourceFtpProfile.path" (change)="onTextChange($event)">
    </div>
</div>
<!-- Archive path -->
<div class="form-group row mb5">
    <label for="sourceFtpProfileArchivePath" class="col-sm-3 col-form-label">Archive path<span class="req-color">*</span></label>
    <div class="col-sm-6">
        <input type="text" class="form-control" id="sourceFtpProfileArchivePath" name="sourceFtpProfileArchivePath" placeholder="Archive path" [(ngModel)]="sourceFtpProfile.archivePath" (change)="onTextChange($event)">
    </div>
</div>
<!-- Encoding -->
<div class="form-group row mb5">
    <label for="sourceFtpProfileEncoding" class="col-sm-3 col-form-label">Encoding<span class="req-color">*</span></label>
    <div class="col-sm-6">
        <input type="text" class="form-control" id="sourceFtpProfileEncoding" name="sourceFtpProfileEncoding" placeholder="Encoding" [(ngModel)]="sourceFtpProfile.encoding" (change)="onTextChange($event)">
    </div>
</div>
<!-- Use RegEx -->
<div class="form-group row mb5">
    <div class="col-sm-3 col-form-label"></div>
    <div class="col-sm-6">
        <div class="form-check">
            <input class="form-check-input" type="checkbox" id="sourceFtpProfileUseRegEx" value="" aria-label="..." [(ngModel)]="sourceFtpProfile.useRegEx">
            <label class="form-check-label" for="sourceFtpProfileUseRegEx">
                Use RegEx
            </label>
        </div>
    </div>
</div>
<!-- FilterOrRegEx -->
<div class="form-group row mb5">
    <label for="sourceFtpProfileFilterOrRegEx" class="col-sm-3 col-form-label">Filter/RegEx<span class="req-color">*</span></label>
    <div class="col-sm-6">
        <input type="text" class="form-control" id="sourceFtpProfileFilterOrRegEx" name="sourceFtpProfileFilterOrRegEx" placeholder="Filter/RegEx" [(ngModel)]="sourceFtpProfile.filterOrRegEx" (change)="onTextChange($event)">
    </div>
</div>
<!-- Delete -->
<div class="form-group row mb5">
    <div class="col-sm-3 col-form-label"></div>
    <div class="col-sm-6">
        <div class="form-check">
            <input class="form-check-input" type="checkbox" id="sourceFtpProfileDelete" value="" aria-label="..." [(ngModel)]="sourceFtpProfile.delete">
            <label class="form-check-label" for="sourceFtpProfileDelete">
                Delete
            </label>
        </div>
    </div>
</div>

<!-- Selection -->
<div class="row mb5">
    <div class="col-sm-3"></div>
    <div class="col-sm-6">
        <div class="form-check">
            <input class="form-check-input" type="radio" name="keyVaultOptions1" [value]="1" [(ngModel)]="keyVaultUpdateType" id="keyVaultOptions1">
            <label class="form-check-label" for="keyVaultOptions1">
                Use existing key vault secret
            </label>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" name="keyVaultOptions2" [value]="2" [(ngModel)]="keyVaultUpdateType" id="keyVaultOptions2" checked>
            <label class="form-check-label" for="keyVaultOptions2">
                Add key vault secret
            </label>
        </div>
        <div class="form-check" *ngIf="sourceFtpProfile.keyVaultProfile != null">
            <input class="form-check-input" type="radio" name="keyVaultOptions3" [value]="3" [(ngModel)]="keyVaultUpdateType" id="keyVaultOptions3">
            <label class="form-check-label" for="keyVaultOptions3">
                Update key vault secret
            </label>
        </div>
    </div>
</div>
<!-- Username -->
<div class="form-group row mb5">
    <label for="sourceFtpProfileUsername" class="col-sm-3 col-form-label">Username<span class="req-color">*</span></label>
    <div class="col-sm-6">
        <input type="text" class="form-control" id="sourceFtpProfileUsername" name="sourceFtpProfileUsername" placeholder="Username" [(ngModel)]="sourceFtpProfile.userName" (change)="onTextChange($event)">
    </div>
</div>
<!-- Key vault secret -->
<div class="form-group row mb5" *ngIf="sbKeyVaultProfiles && keyVaultUpdateType === 1">
    <label for="keyVaultSecretDDL" class="col-sm-3 col-form-label control-label">Key vault secret</label>
    <div class="col-sm-6">
        <select dropdown (change)="selectKeyVaultProfileChangeHandler($event)" id="keyVaultSecretDDL" name="keyVaultSecretDDL" class="form-select form-select-sm control-label" [(ngModel)]="sourceFtpProfile.keyVaultProfileId">
            <option value="-1">--- Select secret ---</option>
            <option *ngFor="let item of sbKeyVaultProfiles" [value]="item.id">
                {{item.name}}
            </option>
        </select>
    </div>
</div>
<!-- Password -->
<div class="form-group row mb5" *ngIf="keyVaultUpdateType === 2 || keyVaultUpdateType === 3">
    <label for="destinationPassword" class="col-sm-3 col-form-label">Password</label>
    <div class="col-sm-6">
        <input type="password" class="form-control" id="destinationPassword" name="destinationPassword" placeholder="Password" [(ngModel)]="sourceFtpProfile.passPhrase">
    </div>
</div>
<!-- System name -->
<div class="form-group row mb5" *ngIf="keyVaultUpdateType === 2">
    <label for="systemName" class="col-sm-3 col-form-label">System name <i class="fa fa-info-circle alfalaval-blue-color" aria-hidden="true" placement="top" ngbTooltip="The secret that will store the FTP password has the form of al-goh-ftp-auth-in-{system name}-password. The value you provide in the System name input will be used to construct the secret name."></i></label>
    <div class="col-sm-6">
        <input type="text" class="form-control" id="systemName" name="systemName" placeholder="System name" [(ngModel)]="systemName" (change)="onSystemNameChange($event)">
    </div>
</div>

<!-- 
authenticationTypeId: number;
-->