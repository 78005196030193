<form [formGroup]="destinationFtpprofileForm">
    <hr>
    <!-- Selection -->
   <div class="form-group row mb5" *ngIf="!isEdit">
       <div class="col-sm-3">        
       </div>
       <div class="col-sm-9">
           <div class="form-check">
               <input class="form-check-input" type="radio" formControlName="useExistingDestination" name="useExistingDestination" (change)="handleDestOptionsChange($event)" [value]="true" id="useExistingDestination">
               <label class="form-check-label" for="useExistingDestination">
               Use existing destination
               </label>
           </div>
           <div class="form-check">
               <input class="form-check-input" type="radio" formControlName="useExistingDestination" name="useExistingDestination" (change)="handleDestOptionsChange($event)" [value]="false" id="useExistingDestination">
               <label class="form-check-label" for="useExistingDestination">
                   Add new destination
               </label>
           </div>
       </div>
   </div>
   
    <!-- Use existing physical destination -->
    <div class="form-group row" *ngIf="destinationFtpprofileForm.value.useExistingDestination === true">
       <label for="confDestinationDDL" class="col-sm-3 col-form-label control-label">Destinations <span class="req-color">*</span></label>
       <div class="col-sm-9">
           <select dropdown formControlName="id" id="confDestinationDDL" name="confDestinationDDL" class="form-select form-select-sm control-label">
           <option value="">--- Select destination ---</option>
           <option *ngFor="let item of ftpprofiles" [value]="item.id">
               {{item.name}} (host: {{item.host}})
           </option>
           </select>
           <div *ngIf="form.id.invalid && (form.id.dirty || form.id.touched)" class="alert alert-danger">
            <div *ngIf="form.id.errors?.['required']">Destination is required.</div>
        </div>
       </div>
   </div>
   
   <!-- New physical destination -->
   <div *ngIf="destinationFtpprofileForm.value.useExistingDestination === false">
        <!-- Host -->
       <div class="form-group row mb5">
           <label for="destinationHost" class="col-sm-3 col-form-label">Host<span class="req-color">*</span></label>
           <div class="col-sm-9">
               <input type="text" formControlName="host" class="form-control" id="destinationHost" name="destinationHost" placeholder="Host">
               <div *ngIf="form.host.invalid && (form.host.dirty || form.host.touched)" class="alert alert-danger">
                <div *ngIf="form.host.errors?.['required']">Host is required.</div>
            </div>
           </div>
       </div>
       <!-- Port -->
       <div class="form-group row mb5">
           <label for="destinationPort" class="col-sm-3 col-form-label">Port</label>
           <div class="col-sm-9">
               <input type="text" formControlName="port" class="form-control" id="destinationPort" name="destinationPort" placeholder="Port">
           </div>
       </div>
       <!-- Path -->
       <div class="form-group row mb5">
           <label for="destinationPath" class="col-sm-3 col-form-label">Path</label>
           <div class="col-sm-9">
               <input type="text" formControlName="path" class="form-control" id="destinationPath" name="destinationPath" placeholder="Path">
           </div>
       </div>
       <!-- Temporary path -->
       <div class="form-group row mb5">
           <label for="destinationTempPath" class="col-sm-3 col-form-label">Temporary path</label>
           <div class="col-sm-9">
               <input type="text" formControlName="tempPath" class="form-control" id="destinationTempPath" name="destinationTempPath" placeholder="Temporary path">
           </div>
       </div>
       <!-- Encoding -->
       <div class="form-group row mb5">
           <label for="destinationEncoding" class="col-sm-3 col-form-label">Encoding</label>
           <div class="col-sm-9">
               <input type="text" formControlName="encoding" class="form-control" id="destinationEncoding" name="destinationEncoding" placeholder="Encoding">
           </div>
       </div>
       <!-- Filename -->
       <div class="form-group row mb5">
           <label for="destinationFilename" class="col-sm-3 col-form-label">Filename</label>
           <div class="col-sm-9">
               <input type="text" formControlName="filename" class="form-control" id="destinationFilename" name="destinationFilename" placeholder="Filename">
           </div>
       </div>
       <!-- Selection -->
       <div class="row">
           <div class="col-sm-3"></div>
           <div class="col-sm-9">
               <div class="form-check">
                   <input class="form-check-input" type="radio" formControlName="useExistingKeyVaultValue" name="useExistingKeyVaultValue" [value]="true" id="useExistingKeyVaultValue">
                   <label class="form-check-label" for="useExistingKeyVaultValue">
                       Use existing key vault secret
                   </label>
               </div>
               <div class="form-check">
                   <input class="form-check-input" type="radio" formControlName="useExistingKeyVaultValue" name="useExistingKeyVaultValue" [value]="false" id="useExistingKeyVaultValue">
                   <label class="form-check-label" for="useExistingKeyVaultValue">
                       Add key vault secret
                   </label>
               </div>
           </div>
       </div>
       
       <!-- Username -->
       <div class="form-group row mb5">
           <label for="destinationUsername" class="col-sm-3 col-form-label">Username</label>
           <div class="col-sm-9">
               <input type="text" formControlName="userName" class="form-control" id="destinationUsername" name="destinationUsername" placeholder="Username">
           </div>
       </div>
       <!-- Key vault secret -->
       <div class="form-group row" *ngIf="destinationFtpprofileForm.value.useExistingKeyVaultValue === true">
           <label for="keyvaultsecretDDL" class="col-sm-3 col-form-label control-label">Key vault secret</label>
           <div class="col-sm-9">
               <select dropdown formControlName="keyVaultProfileId" id="keyvaultsecretDDL" name="keyvaultsecretDDL" class="form-select form-select-sm control-label">
                   <option value="-1">--- Select secret ---</option>
                   <option *ngFor="let item of keyVaultProfiles" [value]="item.id">
                       {{item.name}}
                   </option>
               </select>
           </div>
       </div>
       <!-- Password -->
       <div class="form-group row mb5" *ngIf="destinationFtpprofileForm.value.useExistingKeyVaultValue === false">
           <label for="destinationPassword" class="col-sm-3 col-form-label">Password</label>
           <div class="col-sm-9">
               <input type="password" formControlName="passPhrase" class="form-control" id="destinationPassword" name="destinationPassword" placeholder="Password">
           </div>
       </div>
       <!-- System name -->
       <div class="form-group row mb5" *ngIf="destinationFtpprofileForm.value.useExistingKeyVaultValue === false">
           <label for="systemName" class="col-sm-3 col-form-label">System name <i class="fa fa-info-circle alfalaval-blue-color" aria-hidden="true" placement="top" ngbTooltip="The secret that will store the FTP password has the form of al-goh-ftp-auth-out-${system name}-password. The value you provide in the System name input will be used to construct the secret name."></i></label>
           <div class="col-sm-9">
               <input type="text" formControlName="systemName" class="form-control" id="systemName" name="systemName" placeholder="System name">
           </div>
       </div>
   </div>
</form>



