import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { KeyVaultProfile } from 'src/app/models/key-vault-profile';
import { DestinationHttpprofile } from '../../../../../models/destination/destination-http-profile';
import { DropDownListModel } from '../../../../../models/dropdown-list-model';
import { KeyVaultService } from 'src/app/common-services/keyvault.service';
import { HttpProfileService } from './http-profile.service';
import { FormGroup, FormGroupDirective, Validators } from '@angular/forms';

@Component({
    selector: "http-profile",
    templateUrl: "./http-profile.component.html"
})
export class HttpProfileComponent implements OnInit {
    @Input() formGroupName: string;
    @Input() isEdit: boolean;

    public destinationHttpprofileForm: FormGroup;

    public keyVaultProfiles: Array<KeyVaultProfile>;
    public httpProfiles: Array<DestinationHttpprofile>;
    
    public authenticationOptions: Array<DropDownListModel> = [
        { value: '0', text: 'None' },
        { value: '1', text: 'Basic' },
        { value: '4', text: 'Shared Bearer Token' },
        { value: '5', text: 'Static Authentication' }
    ];

    constructor(private keyVaultService: KeyVaultService, private httpProfileService: HttpProfileService, private rootFormGroup: FormGroupDirective) {}

    get form() { return this.destinationHttpprofileForm.controls; }

    ngOnInit(): void {
        this.destinationHttpprofileForm = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;

        this.httpProfileService.getAllHttpProfiles().subscribe({
            next: (result) => {
                this.httpProfiles = result;
            },
            // TODO Add better error hanlding to present the issue!
            error: (error) => {
                //this.showSpinner = false;
                console.error(error);
            }
        });

        this.keyVaultService.getKeyVaultProfiles().subscribe({
                next: (result) => {
                    this.keyVaultProfiles = result;
                    //this.selectedKeyVaultProfileId = this.destinationHttpProfile.keyVaultProfileId.toString();
                },
                // TODO Add better error hanlding to present the issue!
                error: (error) => {
                    //this.showSpinner = false;
                    console.error(error);
                }
        });
    }

    public handleDestOptionsChange(event: any) {
        const id = this.destinationHttpprofileForm.get('id');
        const host = this.destinationHttpprofileForm.get('host');
        if(this.destinationHttpprofileForm.value.useExistingDestination === true) {
            id.setValue('');
            id.setValidators([Validators.required]);
            host.setValidators(null);
            
        } else {
            id.setValue(0);
            id.setValidators(null); // We could have required here as we set the id to 0 that is a value, but as we hard code...naahh
            host.setValidators([Validators.required]);            
        }
        id.updateValueAndValidity();
        host.updateValueAndValidity();
    }


    public handleKeyVaultOptionsChange(event: any) {
        // TODO If new secret is added some fields are requeired

        //     // const destinationHttpprofileId = this.destinationHttpprofileForm.get('destinationHttpprofileId');
        //     // const host = this.destinationHttpprofileForm.get('host');

        //     // if(this.destinationHttpprofileForm.value.useExistingDestination === false) {
        //     //     destinationHttpprofileId.setValidators(null);
        //     //     host.setValidators([Validators.required]);            
        //     // } else {
        //     //     destinationHttpprofileId.setValidators([Validators.required]);
        //     //     host.setValidators(null);
        //     // }
    }

    // TODO Remove after testing
    // getValidators(control: AbstractControl): string {
    //     const validatorFns = control.validator ? [control.validator] : [];
    
    //     if (control.asyncValidator) {
    //       validatorFns.push(control.asyncValidator);
    //     }
    
    //     return validatorFns
    //       .map(validatorFn => this.mapValidatorFnToName(validatorFn))
    //       .filter(name => !!name)
    //       .join(', ');
    // }
    
    // private mapValidatorFnToName(validatorFn: any): string {
    //     if (validatorFn === Validators.required) {
    //       return 'required';
    //     }
    //     if (validatorFn === Validators.email) {
    //       return 'email';
    //     }
    //     if (validatorFn === Validators.minLength) {
    //       return 'minLength';
    //     }
    //     if (validatorFn === Validators.maxLength) {
    //       return 'maxLength';
    //     }
    //     if (validatorFn === Validators.pattern) {
    //       return 'pattern';
    //     }
    //     // Add more cases for other validators as needed
    
    //     return 'customValidator';
    // }
}