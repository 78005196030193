import { Component, Input, OnInit } from '@angular/core';
import { KeyVaultProfile } from 'src/app/models/key-vault-profile';
import { DestinationSftpprofile } from 'src/app/models/destination/destination-sftp-profile';
import { KeyVaultService } from 'src/app/common-services/keyvault.service';
import { SftpProfileService } from './sftp-profile.service';
import { FormGroup, FormGroupDirective } from '@angular/forms';

@Component({
    selector: "sftp-profile",
    templateUrl: "./sftp-profile.component.html"
})
export class SftpProfileComponent implements OnInit {
    @Input() formGroupName: string;
    @Input() isEdit: boolean;

    public destinationSftpprofileForm: FormGroup;
    public keyVaultProfiles: Array<KeyVaultProfile>;
    public sftpprofiles: Array<DestinationSftpprofile>;

    constructor(private keyVaultService: KeyVaultService, private sftpProfileService: SftpProfileService, private rootFormGroup: FormGroupDirective) {}

    get form() { return this.destinationSftpprofileForm.controls; }

    ngOnInit(): void {
        this.destinationSftpprofileForm = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;

        this.sftpProfileService.getAllSftpProfiles().subscribe({
            next: (result) => {
                this.sftpprofiles = result;
            },
            // TODO Add better error hanlding to present the issue!
            error: (error) => {
                //this.showSpinner = false;
                console.error(error);
            }
        });

        this.keyVaultService.getKeyVaultProfiles().subscribe({
            next: (result) => {
                this.keyVaultProfiles = result;
            },
            // TODO Add better error hanlding to present the issue!
            error: (error) => {
                //this.showSpinner = false;
                console.error(error);
            }
        });
    }

    public handleDestOptionsChange(event: any) {
        this.sftpProfileService.setupRequiredValidation(this.destinationSftpprofileForm);
    }
}